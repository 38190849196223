<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter">
      <label>Date</label>
      <flat-pickr
        v-model="filter.date"
        :config="configDate"
        placeholder="Select Date"
        name="birthdate"
      ></flat-pickr>
      <label>Couriers</label>
      <select v-model="filter.provider_id" @change="fetchServices">
        <option value>Select Carrier</option>
        <option
          v-for="(provider,i) in providers"
          :key="'provider_dropdown'+i"
          :value="provider.id"
        >{{provider.name}}</option>
      </select>
      <label>Services</label>
      <select v-model="filter.service_id" :disabled="services.length == 0">
        <option value>Select Service</option>
        <option
          :value="service.id"
          v-for="(service,i) in services"
          :key="'service_dropdown'+i"
        >{{service.name}}</option>
      </select>
      <input type="button" value="Submit" @click="fetchSlips" />
    </div>
    <loader v-if="loading" />
    <div v-if="!loading && Object.keys(list).length > 0">
      <div class="default_container">
        <div v-for="(couriers,key,i) in list" :key="'slips'+i">
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table2">
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>{{user.division}}</td>
                  <td>
                    <div class="dispatch_date">
                      Dispatch date:
                      <span>{{moment(filter.date).format("MM-DD-YYYY")}}</span>
                    </div>
                    <barcode :value="key" tag="img" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table3">
                <tr>
                  <td>REF</td>
                  <td>ADDRESSEE</td>
                  <td>ITEM</td>
                  <td>ITEM DESCRIPTION</td>
                  <td>SENDER</td>
                </tr>
                <tr v-for="(data,j) in couriers" :key="'courier'+i+j">
                  <td>
                    <barcode :value="data.booking_prefix + data.booking_id" tag="img" />
                  </td>
                  <td>
                    <p v-if="data.delivery_name">{{data.delivery_name}}</p>
                    <p v-if="data.delivery_address_1">{{data.delivery_address_1}}</p>
                    <p v-if="data.delivery_address_2">{{data.delivery_address_2}}</p>
                    <p v-if="data.delivery_city">{{data.delivery_city}}</p>
                    <p v-if="data.delivery_state">{{data.delivery_state}}</p>
                    <p v-if="data.delivery_country">{{data.delivery_country}}</p>
                  </td>
                  <td>{{data.item_qty}}</td>
                  <td>{{data.goods_description || 'NA'}}</td>
                  <td>{{data.pickup_name}}</td>
                </tr>
              </table>
            </div>
          </section>
          <h3>{{couriers.length}} Total Pcs</h3>
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table4">
                <tr>
                  <td>
                    <div class="small_text">Prepared by(Branch Representative)</div>
                    <div class="big_text">{{user.name}}</div>
                    <br />Signature over Printed Name
                  </td>
                  <td>
                    <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>Date
                  </td>
                  <td>
                    <div class="small_text">Checked By: (Branch Personal)</div>
                    <hr />
                    <div class="small_text">Signature over Printed Name</div>
                  </td>
                  <td>
                    <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>Date
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="small_text">Acknowledged by: (Recipient)</div>
                    <br />
                    <hr />Signature over Printed Name
                  </td>
                  <td>
                    <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>
                    <hr />Date
                  </td>
                  <td></td>
                  <td>
                    <div class="big_text">{{moment(Date.now()).format("HH:mm:ss")}}</div>Time
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
      <section class="centered">
        <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
        <a class="link_bt bt_cancel" @click="printTransmittalSlip">Print</a>
        <a
          class="link_bt bt_save"
          @click="dispatchSlip"
          :disabled="dispatch_btn.disabled"
        >{{dispatch_btn.value}}</a>
      </section>
    </div>
    <h3 v-if="!loading && Object.keys(list).length == 0" class="text-center p-3">No data found</h3>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "x",
  components: {
    flatPickr
  },
  created() {
    this.$emit("breadCrumbData", ["Hub Dispatch", "View Transmittal Slip"]);
    this.filter.date = this.moment(new Date(Date.now())).format("YYYY-MM-DD");
    this.fetchUser();
    this.fetchSlips();
    this.fetchProviders();
  },
  data() {
    return {
      date: null,
      enableTime: true,
      configDate: {
        altInput: true,
        noCalendar: false,
         format: "d-m-Y",
          altFormat: "m-d-Y",
      },
      list: {},
      filter: {
        date: "",
        provider_id: "",
        service_id: ""
      },
      user: {
        name: "",
        branch_code: "",
        division: ""
      },
      services: [],
      providers: [],
      loading: true,
      dispatch_btn: {
        disabled: false,
        value: "PRINT AND DISPATCH"
      }
    };
  },
  methods: {
    fetchProviders() {
      this.axios
        .get("api/provider")
        .then(response => {
          this.providers = response.data.providers;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchServices() {
      this.services = [];
      this.axios
        .get("api/provider/services/" + this.filter.provider_id)
        .then(response => {
          this.services = response.data.services;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchUser() {
      this.axios
        .get("/api/user/data")
        .then(response => {
          this.user.name = response.data.msg.name;
          this.user.branch_code = response.data.msg.branch.branch_id;
          this.user.division = response.data.msg.division.name;
        })
        .catch(error => {
          this.toast.error(error);
        });
    },
    fetchSlips() {
      this.loading = true;
      this.axios
        .post("/api/hub/slip/generated", this.filter)
        .then(response => {
          this.list = response.data.list;
          this.loading = false;
        })
        .catch(error => {
          this.toast.error(error);
          this.loading = false;
        });
    },
    dispatchSlip() {
      this.dispatch_btn.disabled = true;
      this.dispatch_btn.value = "DISPATCHING... PLEASE WAIT";
      this.axios
        .post("api/hub/slip/dispatch", this.filter)
        .then(response => {
          this.toast.success(response.data.msg);
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";
          this.$router.push("/pages/CreateDispatchDeliveryNote/Hub");
        })
        .catch(error => {
          console.log(error);
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";
        });
    },
    printTransmittalSlip() {
      window.print();
    }
  }
};
</script>
<style>
.transmit_table2 tr td img,
.transmit_table3 tr td img {
  height: 110px !important;
  width: 100%;
}

.text-center {
  text-align: center;
}
.p-3 {
  padding: 1.5rem;
}
</style>


