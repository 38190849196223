<template>
  <div class="common_sub_container">
    <section>
      <div class="address_book_primary_view">
        <a class="bt_add_contact" v-if="auth().user_type == 'super_admin'" @click="showAddNew = true">ADD NEW ADDRESS</a>
        <a class="bt_edit_contact" @click="showEditContact = true">VIEW ADDRESS BOOK</a>
        <h3>Upload your Address Book</h3>
        <p>
          Add multiple contacts at once by uploading an export of your contact list as a .CSV(max 2MB) or .xlsx(max 1MB)file.
          Download the CSV-template or XLSX-template to show you the format to use.
          Need help exporting your contacts? Checkout the article.
          You can download sample .csv format
          <a :href="sample_url" download>here</a>.
        </p>

        <label class="bt_upload_file"  v-if="auth().user_type == 'super_admin'">
          CHOOSE A FILE
          <input type="file" ref="myFiles" @change="handleFileUpload"/>
        </label>
        <!-- <div class="w-100 p-2" v-if="error_data.length > 0">
          <h4 class="mb-3">The excel sheet contains the following errors</h4>
          <div
            class="text-danger"
            v-for="(error, i) in error_data"
            :key="'error_' + i"
          >
            <p class="font-weight-bold mb-2">In row {{ error.row }},</p>
            <p
              class="error-desc"
              v-for="(suberrors, j) in error.errors"
              :key="'suberrors_' + i + j"
            >
              {{ suberrors }}
            </p>
          </div>
        </div> -->
      </div>
    </section>
    <section></section>

    <div class="black-overlay" v-if="showAddNew">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <AddContact v-if="showAddNew" @closeModal="showAddNew = false" />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popErrorList
            :errors="showpopSelectDestination"
            v-if="showpopSelectDestination"
            @closeModal="closeConsignment"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="showEditContact">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal v-if="showEditContact" @closeModal="showEditContact = false" :hideCheckbox="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddContact from "./AddContact.vue";
import AddressBookModal from "./AddressBookModal.vue";
import popErrorList from "./popups/popErrorList.vue";
export default {
  name: "AddressBook",
  components: {
    AddContact,
    AddressBookModal,
    popErrorList
  },
  created(){
    this.$emit("breadCrumbData",["Address Book"]);
    this.sample_url = process.env.VUE_APP_API_URL + '/api/address/sample?token='+localStorage.token;
  },
  data() {
    return {
      showpopSelectDestination:"",
      isDisabled: false,
      showAddNew: false,
      showEditContact: false,
      files: "",
      sample_url:"",
      error_data: []
    };
  },
  methods: {
    handleFileUpload() {
     
      this.files = this.$refs.myFiles.files[0];
      let formData = new FormData();
      formData.append("address", this.files);

      this.axios
        .post("/api/address/batch", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.toast.success(response.data.msg);
          this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.showpopSelectDestination = error.response.data.errors;
          }
        });
    },
    closeConsignment(){
      this.showpopSelectDestination = '';
      this.$router.go(this.$router.currentRoute);
    },
  }
};
</script>

<style  lang="scss"  >
.common_sub_container {
  padding: 20px;
}
.common_sub_container section {
  padding: 20px 0 0 0;
}
.address_book_primary_view {
  background: #f5f5f5;
  width: 80%;
  height: 400px;
  padding: 80px;
  text-align: center;
  color: #333;
  font-size: 16px;
  margin: 100px auto;
  position: relative;
  h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 50px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .bt_add_contact {
    display: block;
    background: #ffd10a;
    color: #333;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .bt_edit_contact {
    display: block;
    background: #ffd10a;
    color: #333;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .bt_upload_file {
    display: block;
    background: #ffd10a;
    color: #333;
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    margin: 40px auto;

    position: relative;
    input[type="file"] {
      width: 200px;
      height: 40px;
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
    }
  }
}

.black-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  .box-modal {
    background-color: white;
    width: 60%;
    min-width: 1100px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 55;
    height: 570px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    border-radius: 3px;
    box-sizing: border-box;
    .body {
      height: 570px;
      overflow-y: auto;
      clear: both;
      .new-contact {
        width: 100%;
        height: 100%;
        /*display: none;*/
        background-color: white;
        z-index: 22;
        transition: 1s ease;
        /*border: 1px solid rgba(0,0,0,0.1);*/
        border-radius: 5px;
        box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0px;
        padding: 5% 2%;
        hr {
          position: relative;
          left: -5%;
          width: 100%;
        }
        h4 {
          text-align: center;
          text-transform: uppercase;
          width: 95%;
          margin-left: 0%;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .close-icon {
          position: absolute;
          right: 1%;
          top: 2%;
          opacity: 0.3;
          cursor: pointer;
          width: 20px;
          height: 20px;
          background: lime;
        }
      }
    }
  }
}
</style>