<template>
  <div class="common_sub_container">
    <section>
      <div class="address_book_primary_view">
        <h3>Upload Bulk Encoding File</h3>
        <p>Choose the Bulk Encoding upload file. Please remember only .csv files are accepted and it should be less than 2mb in size.</p>
        <p>
          You can download sample .csv format
          <a :href="sample_url">here</a>
        </p>
        <!-- I created -->
        <label class="bt_upload_file">
          CHOOSE A FILE
          <input type="file" ref="myFiles" @change="handleFileUpload" v-if="!loading" />
        </label>
        
        <!-- I created -->
      </div>
    </section>
    <section></section>

    <div class="black-overlay" v-show="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popErrorList
            :errors="showpopSelectDestination"
            v-if="showpopSelectDestination"
            @closeModal="closeConsignment"
          />
        </div>
      </div>
    </div>


  </div>
</template>
<script>

import popErrorList from "./popups/popErrorList.vue";

export default {
  name: "BulkBookingUser",
  components: {
    popErrorList
  },
  created(){
    this.$emit("breadCrumbData",["Create Booking","User Bulk Booking"]);
    this.sample_url = process.env.VUE_APP_API_URL + "/api/booking/user/bulkbooking/sample?token="+localStorage.token;
  },
  data() {
    return {
      showpopSelectDestination:"",
      files: "",
      sample_url:"",
      error_data: [],
      loading:false
    };
  },
  methods: {
    handleFileUpload() {
      this.error_data = [];
      this.files = this.$refs.myFiles.files[0];
      let formData = new FormData();
      formData.append("sample_file", this.files);
      this.axios
        .post("/api/booking/user/bulkbooking", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response);
          this.toast.success(response.data.msg);
          this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.showpopSelectDestination = error.response.data.errors;
          }
        });
    },
    closeConsignment(){
      this.showpopSelectDestination = '';
      this.$router.go(this.$router.currentRoute);
    },
  }
};
</script>
<style lang="scss">
.error-desc {
  font-size: 0.9rem !important;
}
</style>