<template>
  <!--sidebar starts-->
  <div class="main">
    <div class="sidebar">
      <div class="logo_div">
        <img src="../assets/logo.png" />
      </div>
      <section>
        <nav>
          <router-link to="/pages/Dashboard" title="DASHBOARD">
            <i class="material-icons">dashboard</i>
            <span>DASHBOARD</span>
          </router-link>
          <a class="subtrigger" title="CREATE BOOKING" v-if="auth().user_type != 'customer_service_user' && auth().user_type != 'super_admin'">
            <i class="material-icons">assignment</i>
            <span>CREATE BOOKING</span>
          </a>
          <div class="submenu">
            <router-link to="/pages/Encoding" title="ENCODE">
              <i class="material-icons">code</i>
              <span>ENCODE</span>
            </router-link>
            <router-link to="/pages/MailRoom" title="MAILROOM" v-if="auth().user_type == 'hub_user' || auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
              <i class="material-icons">email</i>
              <span>MAILROOM</span>
            </router-link>
            <router-link
              to="/pages/BulkBooking"
              title="INVOICE UPLOAD"
              v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'"
            >
              <i class="material-icons">collections</i>
              <span>INVOICE UPLOAD</span>
            </router-link>
            <router-link
              to="/pages/TransportStatusBooking"
              title="TRANSPORT STATUS UPLOAD"
              v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'"
            >
              <i class="material-icons">collections</i>
              <span>BULK TRANSPORT STATUS UPLOAD</span>
            </router-link>
            <router-link to="/pages/BulkBookingUser" title="USER BULK BOOKING" v-if="auth().user_type == 'user'">
              <i class="material-icons">collections</i>
              <span>USER BULK BOOKING</span>
            </router-link>
          </div>
          <router-link to="/pages/ViewPending" title="VIEW PENDING" v-if="auth().user_type != 'customer_service_user'">
            <i class="material-icons">inbox</i>
            <span>VIEW PENDING</span>
          </router-link>
          <a class="subtrigger" title="DISPATCH" v-if="auth().user_type != 'customer_service_user' && auth().user_type != 'super_admin'">
            <i class="material-icons">receipt</i>
            <span>DISPATCH ENCODE</span>
          </a>
          <div class="submenu">
            <router-link to="/pages/GenerateSlip" title="GENERATE SLIP">
              <i class="material-icons">receipt</i>
              <span>GENERATE SLIP</span>
            </router-link>
            <router-link to="/pages/ViewTransmittalSlipBranch" title="VIEW TRANSMITTAL SLIP">
              <i class="material-icons">receipt</i>
              <span>VIEW TRANSMITTAL SLIP</span>
            </router-link>
            <router-link to="/pages/CreateDispatchDeliveryNote" title="DELIVERY DISPATCH NOTE">
              <i class="material-icons">receipt</i>
              <span>DELIVERY DISPATCH NOTE</span>
            </router-link>
          </div>
          <a
            class="subtrigger"
            title="HUB DISPATCH"
            v-if="auth().user_type == 'hub_user' || auth().user_type == 'hub_admin'"
          >
            <i class="material-icons">receipt</i>
            <span>HUB DISPATCH</span>
          </a>
          <div class="submenu">
            <router-link to="/pages/CreateTransmittalSlipHubAdmin" title="GENERATE SLIP SEARCH">
              <i class="material-icons">receipt</i>
              <span>GENERATE SLIP SEARCH</span>
            </router-link>
            <router-link to="/pages/CreateTransmittalSlipHubAdminByScan" title="GENERATE SLIP SCAN">
              <i class="material-icons">receipt</i>
              <span>GENERATE SLIP SCAN</span>
            </router-link>
            <router-link to="/pages/ViewTransmittalSlip" title="VIEW TRANSMITTAL SLIP">
              <i class="material-icons">receipt</i>
              <span>VIEW TRANSMITTAL SLIP</span>
            </router-link>
            <router-link to="/pages/CreateDispatchDeliveryNote/Hub" title="DELIVERY DISPATCH NOTE">
              <i class="material-icons">receipt</i>
              <span>DELIVERY DISPATCH NOTE</span>
            </router-link>
          </div>
          <router-link to="/pages/TrackTrace" title="TRACK AND TRACE">
            <i class="material-icons">motorcycle</i>
            <span>TRACK AND TRACE</span>
          </router-link>
          <router-link to="/pages/ViewProcessed" title="VIEW PROCESSED" v-if="auth().user_type != 'customer_service_user'">
            <i class="material-icons">history</i>
            <span>VIEW PROCESSED</span>
          </router-link>
        <!--  <router-link to="/pages/AddressBook" title="ADDRESS BOOK" v-if="auth().user_type != 'customer_service_user'">-->
          <router-link to="/pages/AddressBook" title="ADDRESS BOOK" v-if="auth().user_type == 'super_admin'">
            <i class="material-icons">account_box</i>
            <span>ADDRESS BOOK</span>
          </router-link>
          <a class="subtrigger" title="REPORTS" v-if="auth().user_type == 'hub_user' || auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
            <i class="material-icons">equalizer</i>
            <span>REPORTS</span>
          </a>
          <div class="submenu">
            <router-link to="/pages/ReportsMailroom" title="MAILROOM REPORTS">
              <i class="material-icons">equalizer</i>
              <span>MAILROOM REPORTS</span>
            </router-link>
            <router-link to="/pages/ReportsDelivery" title="DELIVERY REPORTS">
              <i class="material-icons">equalizer</i>
              <span>DELIVERY REPORTS</span>
            </router-link>
          </div>
          <router-link to="/pages/ProfileSettings" title="PROFILE AND SETTINGS">
            <i class="material-icons">settings_applications</i>
            <span>PROFILE AND SETTINGS</span>
          </router-link>
          <router-link to="/pages/PasswordManagement" title="PASSWORD MANAGEMENT" v-if="auth().user_type == 'super_admin'">
            <i class="material-icons">network_locked</i>
            <span>PASSWORD MANAGEMENT</span>
          </router-link>
          <hr v-if="auth().user_type == 'super_admin'"/>
          <a class="subtrigger" title="MASTER DATA" v-if="auth().user_type == 'super_admin'">
            <i class="material-icons">call_to_action</i>
            <span>MASTER DATA</span>
          </a>
          <div class="submenu">
            <router-link to="/pages/ModeOfDispatch" title="MODE OF DISPATCH">
              <i class="material-icons">code</i>
              <span>MODE OF DISPATCH</span>
            </router-link>
            <router-link to="/pages/TypeOfGood" title="TYPE OF GOOD">
              <i class="material-icons">code</i>
              <span>TYPE OF GOOD</span>
            </router-link>
            <router-link to="/pages/Carriers" title="CARRIERS">
              <i class="material-icons">code</i>
              <span>CARRIERS</span>
            </router-link>
            <router-link to="/pages/CarrierServices" title="CARRIER SERVICES">
              <i class="material-icons">code</i>
              <span>CARRIER SERVICES</span>
            </router-link>
            <router-link to="/pages/Unit" title="BRANCH">
              <i class="material-icons">code</i>
              <span>BRANCH</span>
            </router-link>
            <!-- <router-link to="/pages/Division" title="DIVISION">
              <i class="material-icons">code</i>
              <span>DIVISION</span>
            </router-link>
            <router-link to="/pages/Hub" title="HUB">
              <i class="material-icons">code</i>
              <span>HUB</span>
            </router-link> -->
            <router-link to="/pages/CostCenter" title="COST CENTER">
              <i class="material-icons">code</i>
              <span>COST CENTER</span>
            </router-link>
            <router-link to="/pages/DepartmentLocations" title="DEPARTMENT LOCATIONS">
              <i class="material-icons">code</i>
              <span>DEPARTMENT/LOCATIONS</span>
            </router-link>
            <router-link to="/pages/MailroomLocations" title="MAILROOM LOCATIONS">
              <i class="material-icons">code</i>
              <span>HUB/MAILROOM LOCATIONS</span>
            </router-link>
            <router-link to="/pages/EmailTemplate" title="EMAIL TEMPLATE">
              <i class="material-icons">code</i>
              <span>EMAIL TEMPLATE</span>
            </router-link>
          </div>
          <router-link to="/pages/UserManagement" title="USER MANAGEMENT" v-if="auth().user_type == 'super_admin'">
            <i class="material-icons">account_circle</i>
            <span>USER MANAGEMENT</span>
          </router-link>
          <router-link to="/pages/help" title="HELP" >
            <i class="material-icons">help</i>
            <span>HELP</span>
          </router-link>
          <router-link to="/pages/support" title="SUPPORT" >
            <i class="material-icons">headset_mic</i>
            <span>SUPPORT</span>
          </router-link>
         <a  title="DISPATCH MANAGER" v-if="user.token && auth().user_type != 'user'" :href="user.token" target="_blank">
            <i class="material-icons">link</i>
            <span>DISPATCH MANAGER</span>
          </a>
          <a  title="GPS LIVE TRACKER" href="#"  v-if="auth().user_type != 'user'">
            <i class="material-icons">gps_fixed</i>
            <span>GPS LIVE TRACKER</span>
          </a>
        </nav>
      </section>
    </div>
    <div class="content-main">
      <MainPanel />
    </div>
  </div>
  <!--sidebar ends-->
</template>

<script>
import MainPanel from "./MainPanel.vue";
import jQuery from "jquery";
const $ = jQuery;
//jQuery script for toggle menu

export default {
  name: "HelloWorld",
  components: {
    MainPanel
  },
  created(){
    // this.dsp_url = process.env.VUE_APP_DSP_URL;
    this.fetchUser();

    try {
      if(this.auth().is_authenticated != 1){
        this.axios.post("api/auth/logout");
        throw "Unauthorized access";
      }
    } catch (e) {
      localStorage.clear();
      this.toast.error("You must log in first");
      this.$router.replace("/");
    }
    window.checkIdleSession = setInterval(this.checkSessionTimeout,20000);
  },
  mounted() {
    $(function() {
      $(".subtrigger").click(function() {
        $(this)
          .next(".submenu")
          .stop()
          .slideToggle();
      });

      $(".menutrigger").click(function() {
        $(
          ".sidebar,.main_panel,nav  a  i.material-icons, .sidebar section div, .sidebar section a.subtrigger, .sidebar nav a span,.sidebar .logo_div img,.sidebar section .submenu a"
        ).toggleClass("activeT");
        /* if ($(".sidebar").hasClass("activeT")) {
          $(".transmittal_slip_filter").addClass("activeT");
        } else {
          $(".transmittal_slip_filter").removeClass("activeT");
        } */
      });
    });
  },
  data(){
    return {
      user:{}
    }
  },
  methods:{
    fetchUser(){
      this.axios.get("api/user/data")
      .then(response => {
        console.log(response);
        this.user = response.data.msg.division;
      });
    },
    async checkSessionTimeout(){
      let idletime = 0;
      if(process.env.VUE_APP_IDLE_TIMEOUT){
        idletime = process.env.VUE_APP_IDLE_TIMEOUT * 1000 * 60;
      }else{
        idletime = 3600000;
      }
      if(localStorage.last_active > Date.now() || localStorage.last_active < Date.now() - idletime){
        await this.axios.post("api/auth/logout");
        localStorage.clear();
        this.$router.replace("/");
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/*menu triggered ----------------------------------- css*/
.sidebar section a.subtrigger.activeT {
  background: none;
}
nav {
  padding-left: 10px;
}
nav a.router-link-exact-active {
  color: #ffd10a !important;
  border-left: 2px solid #ffd10a;
}
nav a i.material-icons {
  position: relative;
  left: 0;
  transition: left 0.5s;
}

nav a i.material-icons.activeT {
  left: 235px;
}

.sidebar .logo_div img {
  opacity: 1;
  transition: opacity 0.5s;
}
.sidebar .logo_div img.activeT {
  opacity: 0;
}
.sidebar nav a span {
  opacity: 1;
  transition: opacity 0.5s;
}
.sidebar nav a span.activeT {
  opacity: 0;
}
.sidebar section nav div.submenu a {
  transition: padding 0.5s;
}
.sidebar section nav div.submenu.activeT a {
  padding-left: 0;
}
.sidebar {
  left: 0;
  transition: left 0.5s;
}
.sidebar.activeT {
  left: -250px;
}

.main_panel {
  transition: margin-left 0.5s, width 0.5s;
}
.main_panel.activeT {
  width: calc(100% - 50px);
  margin-left: 50px;
}

.main_panel > header {
  transition: width 0.5s, left 0.5s;
}
.main_panel.activeT > header {
  left: 50px;
  width: calc(100% - 50px);
}
.main_panel .under_header {
  transition: width 0.5s, left 0.5s;
}
.main_panel.activeT .under_header {
  left: 50px;
  width: calc(100% - 50px);
}

.main_panel .transmittal_slip_filter {
  transition: left 0.5s, width 0.5s;
}
.main_panel.activeT .transmittal_slip_filter {
  left: 50px;
  width: calc(100% - 50px);
}

/*menu triggered ----------------------------------- css ENDS*/
.main {
  width: 100%;
  display: flex;
}
.content-main {
  width: 100%;

  overflow: auto;
  height: auto;
}
.sidebar {
  width: 300px;
  height: 100%;
  background: #1e1e1e;
  color: #fff;
  flex-shrink: 0;
  overflow-y: scroll;
  position: fixed;
  .logo_div {
    width: 100%;
    height: auto;
    padding: 20px 0;
    background: #323232;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.sidebar section {
  margin: 10px 0 20px;
  header {
    font-size: 18px;
    padding: 0 0 10px 20px;
  }
  a {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0;
    box-sizing: border-box;
    font-weight: 300;
    cursor: pointer;
    font-size: 13px;
    color: #fff;
    &.subtrigger {
      background: url(../assets/ico-down-arrow.png) no-repeat;
      background-position: right 20px top 50%;
    }
    &:hover {
      color: #ddd;
      .icon {
        background-position: 0 -33px;
      }
    }
    .icon {
      width: 32px;
      height: 32px;
      background-image: url(../assets/ico_settings.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 0 -0;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .submenu {
    background: #000;
    display: none;
    a {
      color: #ccc;
      border-bottom: 1px solid #111;
      padding-left: 15px;
      &:hover {
        color: #fff;
      }
    }
  }
}
.material-icons {
  font-size: 18px;
  position: relative;
  top: 5px;
  margin: 0 10px;
}
</style>
