<template>
  <div class="popup_sub_container add_contact_adj">
    <h2 v-if="edit">EDIT CONTACT</h2>
    <h2 v-else>ADD CONTACT</h2>
    <i class="closeAddContact" @click="closeModal">X</i>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <ValidationProvider name="category" rules="required" v-slot="{ errors }" slim>
      <section class="search_section">
        <h5>Choose a category</h5>
        <select v-model="address.category" @change="clearData" :class="errors[0] ? 'warning-border':''">
          <option value="">Select a category</option>
          <option value="internal">Internal</option>
          <option value="external">External</option>
        </select>
      </section>
    </ValidationProvider>
    <section>
      <h3 class="sub_head">PRIMARY ADDRESS</h3>
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Full Name
                  <span>*</span>
                </label>
                <input type="text" v-model="address.name" placeholder="Full Name Character Limit 30" :class="errors[0] ? 'warning-border':''"  :maxlength="max2"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Branch Name
                </label>
                  <div v-if="address.category === 'internal'">
                    <select v-model="address.branch_name" @change="fetchBranchDetail">
                      <option value="">Select a branch</option>
                      <option v-for="(branch,i) in branches" :key="'branches'+i" :value="branch.name">{{branch.name}}</option>
                    </select>
                  </div>
                  <div v-else>
                    <input type="text" v-model="address.branch_name"  placeholder="Branch Name Character Limit 30" class="dynamic_data_trigger"  :maxlength="max2"/>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Department Name
                </label>
                <div v-if="address.category === 'internal'">
                  <select v-model="address.department_id">
                    <option value="">Select a department</option>
                    <option v-for="(department,i) in departments" :key="'department'+i" :value="department.department_name">{{department.department_name}}</option>
                  </select>
                </div>
                <div v-else>
                  <input type="text" v-model="address.department_id"  placeholder="Department Name Character Limit 30" class="dynamic_data_trigger"  :maxlength="max2"/>
                </div>

                
              </div>
            </td>
            <td>
              <ValidationProvider name="company name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Company
                  <span>*</span>
                </label>
                <input type="text" v-model="address.company_name" placeholder="Company Character Limit 30"  :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="address1" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address line 1
                  <span>*</span>
                </label>
                  <div v-if="address.category === 'internal'">
                    <input type="text" v-model="address.address_1" placeholder="Address line 1 Character Limit 60"  :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                  <div v-else>
                    <input type="text" v-model="address.address_1" placeholder="Address line 1 Character Limit 60" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                  </div>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="address 2" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address line 2
                  <span>*</span>
                </label>
                <input type="text" v-model="address.address_2" placeholder="Address line 2 Character Limit 60" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Postal Code
                </label>
                <input type="text" v-model="address.postal_code" placeholder="Postal Code Character Limit 10" :maxlength="max1"/>
              </div>
            </td>
            <td>
              <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  City
                  <span>*</span>
                </label>
                <input type="text" :class="errors[0] ? 'warning-border':''" placeholder="City Character Limit 30" v-model="address.city" :maxlength="max2"/>
              </div>
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Suburb
                </label>
                <input type="text" v-model="address.suburb" placeholder="Suburb Character Limit 30"  :maxlength="max2"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Country
                </label>
                <input type="text" v-model="address.country" placeholder="Country Character Limit 30"  :maxlength="max2"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  State/Province
                  <span>*</span>
                </label>
                <input type="text" v-model="address.state" placeholder="State/Province Character Limit 30"  :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
              </ValidationProvider>
            </td>
            
            <td>
            <ValidationProvider name="email" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Email
                  <span>*</span>
                </label>
                <input type="text" v-model="address.email" placeholder="Email Character Limit 60"  :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Phone
                </label>
                <input type="text" v-model="address.phone" placeholder="Phone Character Limit 20" :maxlength="max4" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <hr />
    <span class="prefered_add">Add an additional preferred address</span>

    <input type="checkbox" class="chk_prefered" value="1" v-bind:true-value="1" v-bind:false-value="0" v-model="address.preferred_address"/>

    <hr />
    <section class="preferred_hide">
      <h3 class="sub_head">PREFERRED ADDRESS</h3>
      <div class="form_section1">
        <table>
          <tr>
            <td>
            <ValidationProvider name="address_1" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address line 1
                  <span>*</span>
                </label>
                <input type="text" placeholder="Address line 1 Character Limit 60" v-model="address.preferred.address_1" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
            <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address line 2
                  <span>*</span>
                </label>
                <input type="text" placeholder="Address line 2 Character Limit 60" v-model="address.preferred.address_2" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Postal Code
                </label>
                <input type="text" placeholder="Postal Code Character Limit 10" v-model="address.preferred.postal_code" :maxlength="max1"/>
              </div>
            </td>
            <td>
            <ValidationProvider name="city p" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  City
                  <span>*</span>
                </label>
                <input type="text" placeholder="City Character Limit 30" v-model="address.preferred.city" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Suburb
                </label>
                <input type="text" placeholder="Suburb Character Limit 30" v-model="address.preferred.suburb" :maxlength="max2"/>
              </div>
            </td>
            <td>
            <ValidationProvider name="state p" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  State/Province
                  <span>*</span>
                </label>
                <input type="text" placeholder="State/Province Character Limit 30" v-model="address.preferred.state" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Country
                </label>
                <input type="text" placeholder="Country Character Limit 30" v-model="address.preferred.country" :maxlength="max2"/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    </ValidationObserver>
    <section class="centered">
      <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
      <a class="link_bt bt_save" @click="submit">Save</a>
    </section>
  </div>
</template>
<script>
export default {
  name: "AddContact",
  data() {
    return {
      max:60,
      max2:30,
      max1:10,
      max4:20,
      modalStatus: false,
      address:{
        category : "",
        name : "",
        company_name : "",
        branch_name : "",
        department_id : "",
        address_1 : "",
        address_2 : "",
        postal_code : "",
        country : "",
        city : "",
        suburb: "",
        state : "",
        phone : "",
        email : "",
        preferred_address:"0",
        preferred:{
          address_1 : "",
          address_2 : "",
          postal_code : "",
          country : "",
          city : "",
          suburb: "",
          state : ""
        }
      },
      branches:[],
      departments:[]
    };
  },
  created(){    
    this.fetchBranch(false);
    if(this.edit){
      this.populateAddress();
    }
  },
  methods: {
    closeModal() {
      this.modalStatus = false;
      this.$emit("closeModal", this.modalStatus);
    },
    fetchBranch(loaddetails = true){
      this.axios.get("/api/branch/view")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
      if(loaddetails){
        this.fetchBranchDetail();
      }
    },
    fetchBranchDetail(){
      this.axios.get("/api/branch/view/"+this.address.branch_name)
      .then(response => {
        let branch = response.data.branches;
        this.address.company_name = branch.company.name;
        this.address.address_1 = branch.address_1;
        this.address.address_2 = branch.address_2;
        this.address.postal_code = branch.postal_code;
        this.address.country = branch.country;
        this.address.city = branch.city;
        this.address.suburb = branch.suburb;
        this.address.state = branch.state;
        this.address.email = branch.email;
        this.address.department_id = "";
        this.fetchDepartment();
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    fetchDepartment(){
      this.axios.get("/api/branch/viewDept/"+this.address.branch_name)
      .then(response => {
        this.departments = response.data.departments;
      })
      .catch(error => {
        console.log(error);
      });
    },
    populateAddress(){
      Object.keys(this.editData).map(element => {
        if(element == "preferred"){
          if(this.editData.preferred == null){
            this.address.preferred_address = 0;
          }else{
            this.address.preferred_address = 1;
            this.address.preferred = this.editData.preferred;
          }
        }else{
          this.address[element] = this.editData[element];
        }
      });
      delete this.address.department;
      delete this.address.is_checked;
      this.fetchDepartment();
    },
    submit(){
      this.$refs.observer.validate();
      if(this.edit){
        this.updateAddress();
      }else{
        this.createAddress();
      }
    },
    createAddress(){
      this.axios.post("/api/address",this.address)
        .then(response => {
            this.toast.success(response.data.msg);
            this.$emit("closeModal");
        })
        .catch(error =>{
            console.log(error);
        });
    },
    updateAddress(){
      console.log(this.address);
      this.axios.put("/api/address/"+this.address.id,this.address)
        .then(response => {
            this.toast.success(response.data.msg);
            this.$emit("closeModal");
        })
        .catch(error =>{
            console.log(error);
        });
    },
    clearData(){
      {
        this.address.company_name = "";
        this.address.branch_name = "";
        this.address.department_id = "";
        this.address.address_1 = "";
        this.address.address_2 = "";
        this.address.postal_code = "";
        this.address.country = "";
        this.address.city  = "";
        this.address.suburb = "";
        this.address.state = "";
        this.address.phone = "";
        this.address.email = "";
        this.address.preferred_address = "0";
        this.address.preferred = {
          address_1 : "",
          address_2 : "",
          postal_code : "",
          country : "",
          city : "",
          suburb: "",
          state : ""
        }
      }
    }
  },
  props:["edit","editData"]
};
</script>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}
.preferred_hide {
  display: none;
}
.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}
.chk_prefered:checked + hr + .preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeAddContact {
  width: 30px;
  height: 30px;
  background: #000;
  color: #ffd10a;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: relative;
  float: right;
  right: 0;
  top: -60px;
  cursor: pointer;
}
</style>
<style scoped>
/*this fix is for table with 2 column only, to keep same width*/
.form_section1 table tr td {
  width: 50%;
}
</style>