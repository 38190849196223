<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter">
      <label>Enter</label>
      <input
        type="text"
        placeholder="Search by Receipt No/Customer Ref/AWB No"
        v-model="receiptNo"
        @keyup.enter="searchReceiptNo"
      />
      <label>Filter By Date</label>
      <flat-pickr
        class="date_for_pos_fixed"
        v-model="daterange"
        :config="configDate"
        placeholder="Date"
        name="birthdate"
        @on-close="dateChanged"
      ></flat-pickr>

      <label v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'">In / Out</label>
      <select v-model="inoutfilter" @change="filterInOut" v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'">
        <option value disabled="disabled" selected="selected">Select status</option>
        <option value="all">All</option>
        <option value="Incoming">Incoming</option>
        <option value="Outgoing">Outgoing</option>
      </select>

      <label>Filter By Status</label>
      <select v-model="status" @change="filterStatus">
        <option value disabled="disabled" selected="selected">Select status</option>
        <option value="all">All</option>
        <option value="Created">Created</option>
        <option value="Delivered">Delivered</option>
        <option value="Dispatched">Dispatched</option>
        <option value="Not Home">Not Home</option>
        <option value="PickedUp">PickedUp</option>
        <option value="Received">Received</option>
        <option value="RTS">RTS</option>
        <option value="Transmitted">Transmitted</option>
        <option value="WC">WC</option>
      </select>

      <label>Type of Jobs</label>
      <select v-model="typeOfJob" @change="filterCourierType">
        <option value="">All</option>
        <option value="Batch Upload">Batch Upload</option>
        <option value="Dispatched Transmittal Slip">Dispatched Transmittal Slip</option>
        <option value="Dispatched Hub Transmittal Slip">Dispatched Hub Transmittal Slip</option>
        <option value="encode">Encode</option>
        <option value="HubTransmittal Slip">HubTransmittal Slip</option>
        <option value="Mailroom">Mailroom</option>
        <option value="Transmittal Slip">Transmittal Slip</option>
      </select>
    </div>
    <section class="print_hide">
      <div class="two_tabs">
        <a class="tab_buttons active">
          COURIER/MAILROOM/ENCODE
          <span class="label">24</span>
        </a>
        <a class="tab_buttons">TRANSPORT</a>
      </div>
      <div class="jobs_table">
        <table>
          <tr>
            <th>DATE</th>
            <th>SERVICE TYPE</th>
            <th>CARRIER</th>
            <th>BOOKED BY</th>
            <th>ITEM TYPE</th>
            <th>PICKUP ADDRESS</th>
            <th>DELIVERY ADDRESS</th>
            <th>PICKUP/DELIVERY INSTRUCTION</th>
            <th>STATUS</th>
          </tr>
          <tbody v-if="!loading">
          <tr v-for="(data,i) in courier" :key="i">
            <td>
              <p class="text-center">
              <span class="label">{{data.booking_type}}</span>
              </p>
              <p class="text-center">{{data.created_at}}</p>
            </td>
            <td>
              <span class="label">{{data.delivery_service}}</span>
              <p>Cut of time: {{data.office_close_time}}</p>
            </td>
            <td>
              <p>{{data.delivery_provider}}</p>
              <div v-if="(data.booking_type != 'Batch Upload') && (data.airway_bill == data.booking_id)"> 
                <p>AWB No:{{data.booking_prefix}}{{data.airway_bill}}
                </p>
            
              </div>
              <div v-else>
                <p>AWB No:{{data.airway_bill}}</p>
              </div> 
            </td>
            <td>
              <p>
                <b>{{data.booking_prefix}}{{data.booking_id}}</b>
              </p>
              
              <p>{{data.requester_name}}</p>
              <p>{{data.created_at}}</p>
              <p>{{data.branch_name}}</p>
            </td>
            <td>{{data.type_of_good}}</td>
            <td>
              <p>Collect from: {{data.package_location}}</p>
              <p>{{data.pickup_name}}</p>
              <p>{{data.pickup_address_1 ? data.pickup_address_1 + ', ':''}}{{data.pickup_address_2 ? data.pickup_address_2 + ', ':""}}{{data.pickup_city ? data.pickup_city+', ':''}}{{data.pickup_state ? data.pickup_state + ', ':''}}{{data.pickup_postal_code ? data.pickup_postal_code + ', ':''}}{{data.pickup_country}}</p>
            </td>
            <td>
              <p>Send to:</p>
              <p>{{data.delivery_name}}</p>
              <p>{{data.delivery_address_1 ? data.delivery_address_1 + ', ':''}}{{data.delivery_address_2 ? data.delivery_address_2+', ':''}}{{data.delivery_city ? data.delivery_city + ', ' : ''}}{{data.delivery_state ? data.delivery_state + ', ':''}}{{data.delivery_postal_code ? data.delivery_postal_code + ', ':''}}{{data.delivery_country}}</p>
            </td>
            <td>
              <p>Item Description:  <span><br/>{{data.goods_description || 'NA'}}</span></p>
              <p>Delivery Instruction: <span><br/>{{data.pickup_instructions || 'NA'}}</span></p>
            </td>
            <td>
              <span class="label green" v-if="auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin'">
                {{data.mail_type}}
              </span>
              <div class="icons_wrapper">
                <div class="view_optons_wrapper" v-if="data.show_view_popup">
                  <a class="view_option_button" @click="searchConsignment(data)">View Receipt</a>
                  <a class="view_option_button" @click="viewSummary(data)">View Summary</a>
                </div>
                <a @click="data.show_view_popup = !data.show_view_popup">
                  <i class="material-icons blue" title="View">remove_red_eye</i>
                </a>
                <a v-if="data.booking_type == 'Encode' && (auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin')">
                    <router-link title="Edit" :to="{ name: 'Encoding', params: { id: data.id }}" v-if="data.is_transmittal_slip == 0 && !data.transmittal_slip_id">
                      <i class="material-icons red" title="Edit">mode_edit</i>
                    </router-link>
                    <i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
                </a>
                <a v-if="data.booking_type == 'MailRoom' && (auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin')">
                  <router-link title="Edit" :to="{ name: 'MailRoom', params: { id: data.booking_id }}">
                     <i class="material-icons red" title="Edit">mode_edit</i>
                  </router-link>
                </a>
                <a>
                  <i class="material-icons grey" title="Update">update</i>
                </a>
                <a v-if="auth().user_type != 'user' && ((data.booking_type == 'Encode' && data.is_transmittal_slip == 0 && !data.transmittal_slip_id) || data.booking_type == 'MailRoom')" @click="deleteBooking(data.id)">
                  <i class="material-icons red" title="Delete">delete</i>
                </a>
                <a v-else>
                <i class="material-icons grey" title="Could not be deleted">delete</i>
                </a>
                <a v-if="data.courier_comments.length > 0" @click="showpopCommentDiary = true">
                  <i class="material-icons green" @click="addcomment(data)" title="Type your Comment here">comment</i>
                </a>
                <a v-else @click="showpopCommentDiary = true">
                  <i class="material-icons orange" @click="addcomment(data)" title="Type your Comment here">comment</i>
                </a>
              </div>
              <a class="bt bt_black">{{data.status}}</a>
              <!-- <a class="bt bt_gold" @click="updatestatus(data)">Update Status</a> -->
              <a class="bt bt_gold" v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin' && data.status != 'Booking uploaded' " @click="updatestatus(data)">Update Status</a>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-if="!loading && courier.length == 0">No data found</p>
      </div>
      <loader v-if="loading"/>
      <paginate
        v-if="pages > 0"
        v-show="!loading"
        v-model="current_page"
        :page-count="pages"
        active-class="paginate-active"
        :click-handler="fetchCouriers"
        prev-link-class="paginate-previous"
        next-link-class="paginate-next"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :no-li-surround="true">
      </paginate>
    </section>
    <div class="black-overlay" v-if="popupdatestatus_booking_id">
      <div class="box-modal smallPops">
        <div class="body">
          <popUpdateStatus
            @closeModal="popupdatestatus_booking_id = '',fetchCouriers(current_page);"
            :booking_id="popupdatestatus_booking_id"
            :booking_prefix="popupdatestatus_booking_prefix"
            v-if="popupdatestatus_booking_id"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="popcommentdiary_id">
      <div class="box-modal smallPops">
        <div class="body">
          <popCommentDiary @closeModal="popcommentdiary_id = ''"
            :id="popcommentdiary_id"
            v-if="popcommentdiary_id"/>
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment
            @closeModal="closeConsignment"
            :booking_id="viewconsignmentpopup_booking_id"
            :booking_prefix="viewconsignmentpopup_booking_prefix"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import popUpdateStatus from "./popups/popUpdateStatus.vue";
import popCommentDiary from "./popups/popCommentDiary.vue";
import ViewConsignment from "./ViewConsignment.vue";
import moment from "moment";

export default {
  name: "ViewPending",
  components: {
    flatPickr,
    popUpdateStatus,
    popCommentDiary,
    ViewConsignment
  },
  data() {
    return {
      receiptNo: "",
      daterange:"",
      status: "",
      inoutfilter:"",
      typeOfJob:"",
      enableTime: true,
      configDate: {
        mode: "range",
         format: "d-m-Y",
          altFormat: "m-d-Y",
          altInput: true
      },
      viewBoxShow: false,
      showpopUpdateStatus: false,
      showpopCommentDiary: false,
      showViewConsignment: false,
      courier: [],
      pages:0,
      current_page:0,
      post_data: {
        from:"",
        to:"",
        value: "",
        statusfilter: "",
        jobType:"",
        inout:""
      },
      viewconsignmentpopup_booking_id: "",
      viewconsignmentpopup_booking_prefix: "",
      popupdatestatus_booking_id: "",
      popupdatestatus_booking_prefix: "",
      popcommentdiary_id: "",
      loading:true
    };
  },
  created() {
    this.$emit("breadCrumbData",["View Pending"]);
    if(localStorage.viewPending){
      let data = JSON.parse(localStorage.viewPending);
      if(data.jobType){
        this.typeOfJob = data.jobType;
        this.post_data.jobType = data.jobType;
      }
      localStorage.removeItem("viewPending");
    }
    this.fetchCouriers();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    searchReceiptNo() {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },
    filterInOut() {
      this.post_data.inout = this.inoutfilter;
      this.fetchCouriers();
    },
    filterStatus() {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    filterCourierType(){
      this.post_data.jobType = this.typeOfJob;
      this.fetchCouriers();
    },
    fetchCouriers(page=1) {
      this.loading = true;
      this.current_page = page;
      this.axios
        .post("/api/courier/"+page+"/listingAndFilter", this.post_data)
        .then(response => {
          console.log(response);
            this.courier = response.data.couriers.list.map(element => {
              element.show_view_popup = false;
              return element;
            });
            this.pages = response.data.couriers.pages;
            this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    closeConsignment(){
      this.viewconsignmentpopup_booking_id = '';
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    },
    searchConsignment(booking) {
      this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
      this.viewconsignmentpopup_booking_id = booking.booking_id;
    },
    viewSummary(data){
      localStorage.view_summary = JSON.stringify({
        booking_id:data.booking_prefix + data.booking_id
      });
      this.$router.push("/pages/ViewProcessed");
    },
    updatestatus(booking) {
      this.popupdatestatus_booking_prefix = booking.booking_prefix;
      this.popupdatestatus_booking_id = booking.booking_id;
    },
    addcomment(id) {
      console.log(id);
      this.popcommentdiary_id = id.id;
      console.log(this.popcommentdiary_id);
    },
    deleteBooking(id){
      if(confirm("Are you sure you want to delete this booking?")){
        this.axios.delete("api/courier/"+id)
                  .then(response => {
                    this.fetchCouriers();
                  })
                  .catch(error => {
                    console.log(error);
                  })
      }
    }
  }
};
</script>
<style lang="scss">
$theme-color: #ffd10a;
.jobs_table {
  border-top: 5px solid $theme-color;
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid #ccc;
    font-size: 14px;
    margin-bottom: 15px;
    tr {
      th {
        background: #323232;
        padding: 20px 10px;
        color: #ccc;
        font-weight: 700;
        border: 1px solid #222;
        border-left: 0;
        border-top: 0;
        text-align: left;
      }
      td {
        background: #eee;
        padding: 20px 10px;
        color: #333;
        font-weight: 400;
        border: 1px solid #ccc;
        border-left: 0;
        border-top: 0;
        text-align: left;
        vertical-align: top;
        p {
          padding: 0 0 10px 0;
          b {
            font-weight: 700;
          }
        }
        p.text-center{
          text-align:center;
        }
        span.label {
          display: inline-block;
          font-weight: 700;
          background: #000;
          color: #fff;
          border-radius: 4px;
          padding: 5px;
          text-align:center;
          margin-bottom:5px;
          &.green {
            background: green;
          }
        }
        .icons_wrapper {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          position: relative;

          a {
            display: inline-block;
            width: 24px;
            height: 24px;
            .material-icons {
              font-size: 18px;
              position: relative;
              top: 0;
              margin: 0;
              font-weight: normal;
              &.blue {
                color: rgb(45, 91, 241);
              }
              &.red {
                color: rgb(241, 74, 45);
              }
              &.green {
                color: green;
              }
              &.orange {
                color: orange;
              }
              &.grey {
                color: grey;
              }

            }
          }
        }
        .bt {
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 4px;
          display: block;
          margin: 5px 0;
        }
        .bt_black {
          background: #000;
          color: #fff;
        }
        .bt_gold {
          background: $theme-color;
          color: #000;
        }
      }
    }
  }
}
.two_tabs {
  padding: 40px 10px 0 10px;
  display: flex;
  justify-content: space-around;
  .tab_buttons {
    display: block;
    width: 40%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #ececec;
    color: #333;
    border: 1px solid #ddd;
    span.label {
      display: none;
      background: $theme-color;
      color: #000;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  .tab_buttons.active {
    background: #323232;
    color: #fff;
  }
}
.view_optons_wrapper {
  width: 240px;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -250px;
  top: 10px;
  padding: 10px;
  a.view_option_button {
    width: 100% !important;
    height: 40px !important;
    background: $theme-color;
    color: #000;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    line-height: 40px;
  }
  a.view_option_button:last-child {
    background: #aaa;
    color: #000;

    margin-bottom: 0;
  }
}
</style>
<style scoped>
.jobs_table table tr td:nth-child(6) p:nth-child(2){
  color:red;
}
.jobs_table table tr td:nth-child(7) p:nth-child(2){
  color:red;
}
</style>