<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
          <tr>
            <th>Carrier Name</th>
            <th>Carrier Code</th>
            <th>Mode Of Dispatch</th>
            <th>Description</th>
            <th>Logo</th>
            <th>Action</th>
          </tr>
          <tr v-for="(carrier,i) in carriers" :key="i">
            <td>
             <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max2"
                  v-model="carrier.name"
                  :disabled="!carrier.is_edit_mode" :class="errors[0] ? 'warning-border':''"
                />
              </div>
               </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30":maxlength="max2"
                  v-model="carrier.carrier_code"
                  :disabled="!carrier.is_edit_mode" :class="errors[0] ? 'warning-border':''"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="dispatch" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <select v-model="carrier.mode" :disabled="!carrier.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                  <option value>Select Mode of Dispatch</option>
                  <option
                    v-for="(mode,j) in dispatches"
                    :key="'mode'+j"
                    :value="mode.id"
                  >{{mode.name}}</option>
                </select>
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="desc" rules="required|max:70" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <textarea
                  placeholder="Type Character Limit 70" :maxlength="max" :class="errors[0] ? 'warning-border':''"
                  v-model="carrier.description"
                  :disabled="!carrier.is_edit_mode"
                ></textarea>
              </div>
               </ValidationProvider>
            </td>
            <td>
            <div class="label_and_element_wrapper">
              <img :src="carrier.logo_img" />
              <label class="user_profile_upload" v-if="carrier.is_edit_mode">
                <span>Choose a File</span>
                <input type="file" accept=".jpg, .PNG" @change="uploadLogo(i)" :ref="'carrier_logo_'+i" :disabled="!carrier.is_edit_mode"/>
              </label>
            </div>
          </td>

            <td class>
              <a class="table_action_btn" title="DELETE" @click="deleteCarrier(carrier.id)">DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="carrier.is_edit_mode"
                @click="cancelCarrier(carrier)"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                @click="updateCarrier(carrier)"
                v-if="carrier.is_edit_mode"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                @click="carrier.is_edit_mode=true"
                v-if="!carrier.is_edit_mode"
              >EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "Carriers",
  components: {},
  data() {
    return {
      max:70,
      max2:30,
      dispatches: [],
      carriers: []
    };
  },
  created() {
    this.fetchCarrier();
    this.fetchModeOfDispatch();
  },
  methods: {
    fetchCarrier() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          console.log(response);
          this.carriers = response.data.providers.map(element => {
            element.is_edit_mode = false;
            element.logo_img = element.logo;
            element.mode =
              element.modes && element.modes.length > 0
                ? element.modes[0].id
                : "";
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchModeOfDispatch() {
      this.axios
        .get("/api/dispatch/")
        .then(response => {
          this.dispatches = response.data.mode_of_dispatch;
        })
        .catch(error => {
          console.log(error);
        });
    },
    uploadLogo(index) {
      this.carriers[index].logo = this.$refs[
        "carrier_logo_" + index
      ][0].files[0];
      this.carriers[index].logo_img = URL.createObjectURL(
        this.$refs["carrier_logo_" + index][0].files[0]
      );
    },
    updateCarrier(carrier) {
       this.$refs.observer.validate();
      let data = new FormData();
      Object.keys(carrier).forEach(element => {
        data.append(element, carrier[element]);
      });
      data.append("mode_of_dispatch[0]", carrier.mode);
      data.append("_method", "PUT");
      this.axios
        .post("/api/provider/" + carrier.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          carrier.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteCarrier(id) {
      if (confirm("Are you sure you want to delete this Carrier ?")) {
        this.axios
          .delete("/api/provider/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchCarrier();
          })
          .catch(error => {
            console.log("error", error.response);
            this.toast.error();
          });
      }
    },
    cancelCarrier() {
      this.fetchCarrier();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1400px;
    padding-right: 20px;
  }
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>