<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Select Carrier
                      <span>*</span>
                    </label>
                    <select v-model="service.provider_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Carrier</option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Service Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.name" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Service Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Code Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.code" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Type
                      <span>*</span>
                    </label>
                    <select v-model="service.type" :class="errors[0] ? 'warning-border':''">
                      <option value="">Select</option>
                      <option value = "D" >Domestic</option>
                      <option value = "I" >International</option>
                      <option value = "M" >Messenger</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrierServices",
  data(){
    return {
      max:30,
      carriers:[],
      service:{
        type : "",
        provider_id:"",
        name:"",
        code:""
      }
    }
  },
  created() {
    this.fetchCarriers();
  },
  methods: {
    fetchCarriers(){
      this.axios.get("/api/provider/")
      .then(response => {
          this.carriers = response.data.providers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/service/",this.service)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
</style>