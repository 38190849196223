<template>
  <div class="common_sub_container">
    <loader v-if="loading"/>
    <h2 v-if="!loading">MAILROOM BOOKING</h2>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <section class="search_section" v-if="!loading">
      <h5>Enter booking ID</h5>
      <input type="text" v-model="booking_id" @keyup.enter="fetchBookingDetail" placeholder="Search" />
    </section>
    <section v-if="!loading">
      <div class="form_section1">
        <table>
          <tr>
            <td>
            <ValidationProvider name="receiver name" rules="required|max:30" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
                <label>
                  Receiver Name
                  <span>*</span>
                </label>
                <input type="text" placeholder="Receiver Name Character Limit 30" :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" v-model="bookingdetail.delivery_name" @focus="show_requester_address = true" @blur="hideRequesterAddress" :maxlength="max2" required="required"/>
                <div class="dynamic_data_wrapper" v-if="show_requester_address">
                  <span 
                        v-for="(address,i) in filterAddress" 
                        :key="'receiver_address'+i"
                        @click="populateAddress(address)"
                        >
                        {{address.name}}
                      </span>
                </div>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="company name" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                <label>
                  Company Name
                  <span>*</span>
                </label>
                <input type="text" placeholder="Company Name Character Limit 30" v-model="bookingdetail.delivery_company"  :class="errors[0] ? 'warning-border':''" :maxlength="max2"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Branch Name
                </label>
                <input type="text" placeholder="Branch Name Character Limit 50" v-model="bookingdetail.delivery_branch" :maxlength="max6"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Department Name
                </label>
                <select v-model="bookingdetail.delivery_department">
                  <option value="">Select Department</option>
                  <option v-for="(department,i) in departments" :key="'delivery_department_code'+i" :value="department.department_code">{{department.department_name}}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="address_1" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address 1
                  <span>*</span>
                </label>
                <input type="text" placeholder="Address 1 Character Limit 60" v-model="bookingdetail.delivery_address_1" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Address 2
                  <span>*</span>
                </label>
                <input type="text" placeholder="Address 2 Character Limit 60" v-model="bookingdetail.delivery_address_2" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="Postal code" rules="required|max:10" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Postal Code
                  <span>*</span>
                </label>
                <input type="text" placeholder="Postal Code  Character Limit 10" v-model="bookingdetail.delivery_postal_code" :maxlength="max3" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  City
                  <span>*</span>
                </label>
                <input type="text" placeholder="City Character Limit 30" v-model="bookingdetail.delivery_city" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Suburb
                </label>
                <input type="text" placeholder="Suburb Character Limit 30" v-model="bookingdetail.delivery_suburb" :maxlength="max2"/>
              </div>
            </td>
            <td>
              <ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  State/Province
                  <span>*</span>
                </label>
                <input type="text" placeholder="State/Province Character Limit 30" v-model="bookingdetail.delivery_state" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="country" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Country
                  <span>*</span>
                </label>
                <input type="text" placeholder="Country Character Limit 30" v-model="bookingdetail.delivery_country" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <!-- <ValidationProvider name="Phone" rules="required|max:20" v-slot="{ errors }" slim> -->
              <div class="label_and_element_wrapper">
                <label>
                  Phone
                  <!-- <span>*</span> -->
                </label>
                <input type="text" placeholder="Phone Character Limit 20" v-model="bookingdetail.delivery_phone" :maxlength="max1"/>
              </div>
            <!-- </ValidationProvider> -->
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Mobile
                </label>
                <input type="text" placeholder="Mobile Character Limit 20" v-model="bookingdetail.delivery_mobile" :maxlength="max1"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Email
                </label>
                <input type="text" placeholder="Email Character Limit 60" v-model="bookingdetail.delivery_email" :maxlength="max"/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="!loading">
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <ValidationProvider name="Sender" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Sender
                  <span>*</span>
                </label>
                <input type="text" placeholder="Sender Character Limit 30" v-model="bookingdetail.requester_name" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="package" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Package Type
                  <span>*</span>
                </label>
                <select @click="fetchPackageType" v-model="bookingdetail.type_of_good" :class="errors[0] ? 'warning-border':''">
                  <option value="" disabled>Select package type</option>
                  <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.type">{{good.type}}</option>
                </select>
              </div>
            </ValidationProvider>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Delivery Provider
                  <span>*</span>
                </label>
                <select  v-model="bookingdetail.incoming_courier">
                  <option value="" disabled>Select</option>
                  <option v-for="(provider,i) in providers" :key="'providers'+i" :value="provider.carrier_code">{{provider.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>AirWay Bill/Ref</label>
                <input type="text" placeholder="AirWay Bill/Ref Character Limit 30" v-model="bookingdetail.airway_bill" :maxlength="max2"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ValidationProvider name="Number of Items" rules="required|max:5" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Number of Items
                  <span>*</span>
                </label>
                <input type="text" placeholder="Number of Items Character Limit 5" v-model="bookingdetail.item_qty" :maxlength="max5" :class="errors[0] ? 'warning-border':''"/>
              </div>
            </ValidationProvider>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Item Description
                </label>
                <input type="text" placeholder="Item Description Character Limit 70" v-model="bookingdetail.goods_description" :maxlength="max4"/>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="label_and_element_wrapper">
                <label>
                  Special Instruction
                </label>
                <textarea placeholder="Special Instruction Character Limit 70" v-model="bookingdetail.pickup_instructions" :maxlength="max4"></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Mailroom storage location
                </label>
                <select  v-model="bookingdetail.mailroom_location" @change="setDefaultEmailTemplate">
                  <option value="" disabled>Select</option>
                  <option v-for="(mailroomlocation,i) in mailroomlocations" :key="'mailroomlocations'+i" :value="mailroomlocation.id">{{mailroomlocation.inbound_location}} </option>
                </select>
                <!-- <select  v-model="bookingdetail.mailroom_location" @change="setDefaultEmailTemplate">
                  <option value="" disabled>Select</option>
                  <option v-for="(mailroomlocation,i) in mailroomlocations" :key="'mailroomlocations'+i" :value="mailroomlocation.id">{{mailroomlocation.outbound_location}}</option>
                </select> -->
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Email Template
                </label>
                <select  v-model="bookingdetail.email_template">
                 <!-- <option value="" disabled>Select</option>-->
                  <option v-for="(emailtemplate,i) in emailtemplates" :key="'emailtemplates'+i" :value="emailtemplate.id">{{emailtemplate.title}}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>Delivery Location</label>
                <select v-model="bookingdetail.delivery_location">
                  <option value="">Select</option>
                  <option v-for="(deliverylocation,i) in deliverylocations" :key="'deliverylocations'+i" :value="deliverylocation.id">{{deliverylocation.inbound_location}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Scanned By
                </label>
                <input type="text" placeholder="Scanned By Character Limit 30" v-model="bookingdetail.scanned_by" :maxlength="max2"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Scanned Date
                </label>
                <flat-pickr
                  :config="configDate"
                  placeholder="Select Date"
                  name="birthdate" v-model="bookingdetail.scanned_date"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Scanned Time
                </label>
                <flat-pickr
                  :config="configTime"
                  placeholder="Select Time"
                  name="birthtime" v-model="bookingdetail.scanned_time"
                ></flat-pickr>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    </ValidationObserver>

    <section class="centered" v-if="!loading">
      <a class="link_bt bt_cancel" @click="$router.push('/pages/Dashboard')">Cancel</a>
      <a class="link_bt bt_save" @click="submit" :disabled="save_btn.disabled">{{save_btn.value}}</a>
    </section>
    <div class="black-overlay" v-if="address_book_modal">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal v-if="address_book_modal" @closeModal="address_book_modal=false" @selectedAddress="selectedAddress"/>
        </div>
      </div>
    </div>

    <div class="black-overlay" v-if="fetched_booking">
      <div class="box-modal receiptPops">
        <div class="body">
          <MailroomReceipt  @closeModal="resetData" :data="fetched_booking" v-if="fetched_booking"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import MailroomReceipt from "./MailroomReceipt.vue";
import moment from "moment";
import AddressBookModal from "./AddressBookModal";

export default {
  name:"MailRoom",
  components: {
    flatPickr,
    MailroomReceipt,
    AddressBookModal
  },
  computed:{
    filterAddress(){
      return this.address_list.filter(element => {
        if(element.name.toLowerCase().includes(this.bookingdetail.delivery_name.toLowerCase())){
          return element;
        }
      })
    }
  },
  data() {
    return {
      // enableTime: true,
      max: 60,
      max2: 30,
      max4: 70,
      max3: 10,
      max1: 20,
      max5: 5,
      max6:50,
      // enableTime: true,
      configTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      configDate: {
         format: "d-m-Y",
          altFormat: "m-d-Y",
          altInput: true
      },
      showMailroomReceipt: false,
      booking_id:"",
      bookingdetail:{
        requester_name:"",
        type_of_good : "",
        incoming_courier : "",
        airway_bill : "",
        delivery_name : "",
        delivery_company :"",
        delivery_branch : "",
        delivery_department :"",
        delivery_address_1 : "",
        delivery_address_2 : "",
        delivery_postal_code : "",
        delivery_city : "",
        delivery_suburb : "",
        delivery_state : "",
        delivery_country : "",
        delivery_phone : "",
        delivery_mobile : "",
        delivery_email : "",
        goods_description : "",
        pickup_instructions : "",
        mailroom_location : "",
        delivery_location : "",
        scanned_by : "",
        scanned_date : this.moment(new Date()).format("YYYY-MM-DD"),
        scanned_time : this.moment(new Date()).format("HH:mm:ss"),
        email_template : "",
        item_qty: ""
      },
      address_list:[],
      departments:[],
      goods:[],
      emailtemplates:[],
      mailroomlocations:[],
      deliverylocations:[],
      providers:[],
      fetched_booking:"",
      show_requester_address:false,
      loading:false,
      save_btn:{
        disabled:false,
        value:"SAVE"
      },
      user:{},
      address_book_modal:false
    };
  },
  created() {
    this.$emit("breadCrumbData",["Create Booking","Mailroom"]);
    this.initializeData();
    if(this.$route.params.id){
      this.booking_id = this.$route.params.id;
      this.fetchBookingDetail();
    }
},
  methods: {
    initializeData(){
      this.fetchDepartment();
      this.fetchPackageType();
      this.fetchDeliveryProvider();
      this.fetchAddresses();
      this.fetchMailroomLocation();
      this.fetchUser();
    },
    resetData(){
      Object.assign(this.$data, this.$options.data.apply(this));
      this.initializeData();
    },
    fetchUser(){
      this.axios.get("api/user")
      .then(response => {
        this.bookingdetail.scanned_by = response.data.user.name;
      });
    },
    selectedAddress(value){
      this.bookingdetail.delivery_name = value.name;
      this.bookingdetail.delivery_company = value.company_name;
      this.bookingdetail.delivery_branch = value.branch_name;
      this.bookingdetail.delivery_department = value.department ? value.department.department_name : "";
      this.bookingdetail.delivery_address_1 = value.address_1;
      this.bookingdetail.delivery_address_2 = value.address_2;
      this.bookingdetail.delivery_postal_code = value.postal_code;
      this.bookingdetail.delivery_city = value.city;
      this.bookingdetail.delivery_suburb = value.suburb;
      this.bookingdetail.delivery_state = value.state;
      this.bookingdetail.delivery_country = value.country;
      this.bookingdetail.delivery_phone = value.phone;
      this.bookingdetail.delivery_mobile = value.mobile;
      this.bookingdetail.delivery_email = value.email;
    },
    populateAddress(data){
      this.bookingdetail.delivery_name = data.name;
      this.bookingdetail.delivery_company = data.company_name;
      this.bookingdetail.delivery_branch = data.branch_name;
      this.bookingdetail.delivery_department = data.department ? data.department.department_name : "";
      this.bookingdetail.delivery_address_1 = data.address_1;
      this.bookingdetail.delivery_address_2 = data.address_2;
      this.bookingdetail.delivery_postal_code = data.postal_code;
      this.bookingdetail.delivery_city = data.city;
      this.bookingdetail.delivery_suburb = data.suburb;
      this.bookingdetail.delivery_state = data.state;
      this.bookingdetail.delivery_country = data.country;
      this.bookingdetail.delivery_phone = data.phone;
      this.bookingdetail.delivery_mobile = data.mobile;
      this.bookingdetail.delivery_email = data.email;
      this.show_requester_address = false;
    },
    hideRequesterAddress(){
      setTimeout(() => {
        this.show_requester_address = false;
      },200);
    },
    fetchBookingDetail(){
      if(this.booking_id){
        this.axios.get("/api/courierbooking/fetchbookingiddetails/"+this.booking_id)
        .then(response => {
          console.log(response);
            this.bookingdetail.delivery_name = response.data.booking_details.delivery_name;
            this.bookingdetail.delivery_company = response.data.booking_details.delivery_company;
            this.bookingdetail.delivery_branch = response.data.booking_details.delivery_branch;
            this.bookingdetail.delivery_department = response.data.booking_details.delivery_department;
            this.bookingdetail.delivery_address_1 = response.data.booking_details.delivery_address_1;
            this.bookingdetail.delivery_address_2 = response.data.booking_details.delivery_address_2;
            this.bookingdetail.delivery_postal_code = response.data.booking_details.delivery_postal_code;
            this.bookingdetail.delivery_city = response.data.booking_details.delivery_city;
            this.bookingdetail.delivery_suburb = response.data.booking_details.delivery_suburb;
            this.bookingdetail.delivery_state = response.data.booking_details.delivery_state;
            this.bookingdetail.delivery_country = response.data.booking_details.delivery_country;
            this.bookingdetail.delivery_phone = response.data.booking_details.delivery_phone;
            this.bookingdetail.delivery_mobile = response.data.booking_details.delivery_mobile;
            this.bookingdetail.delivery_email = response.data.booking_details.delivery_email;
            this.bookingdetail.requester_name = response.data.booking_details.requester_name;
            this.bookingdetail.type_of_good = response.data.booking_details.type_of_good;
            this.bookingdetail.airway_bill = response.data.booking_details.airway_bill;
            this.bookingdetail.item_qty = response.data.booking_details.item_qty;
            this.bookingdetail.goods_description = response.data.booking_details.goods_description;
            this.bookingdetail.pickup_instructions = response.data.booking_details.pickup_instructions;
            if(response.data.booking_details.email_template){
              this.bookingdetail.email_template = response.data.booking_details.email_template;
            }
            if(response.data.booking_details.mailroom_location){
              this.bookingdetail.mailroom_location = response.data.booking_details.mailroom_location;
            }
            if(response.data.booking_details.incoming_courier != null){
              this.bookingdetail.incoming_courier = response.data.booking_details.incoming_courier;
            } else {
              this.bookingdetail.incoming_courier = response.data.booking_details.carrier_code;
            }
        })
        .catch(error => {
          alert("Booking ID not found");
          this.address_book_modal = true;
          this.booking_id = "";
        });
      }
    },
    fetchDepartment(){
      if(this.bookingdetail.delivery_branch){
        this.axios.get("/api/location/showdepartment/"+this.bookingdetail.delivery_branch)
          .then(response => {
        console.log(response);
            this.departments = response.data.department;
            if(this.departments.length > 0){
              this.bookingdetail.delivery_department = this.departments[0].department_code;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }else{
        this.departments = [];
      }
    },
    fetchPackageType(){
      this.axios.get("/api/goods/")
      .then(response => {
        this.goods = response.data.goods;
        /*if(!this.$route.params.id && this.goods.length > 0){
          this.bookingdetail.type_of_good = this.goods[0].id;
        }*/
      })
      .catch(error => {
        this.toast.error();
      });
    },
    fetchDeliveryProvider(){
      this.axios.get("/api/provider/")
      .then(response => {
        this.providers = response.data.providers;
        if(!this.$route.params.id && this.providers.length > 0){
          this.bookingdetail.incoming_courier = this.providers[0].carrier_code;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchMailroomLocation(){
      this.axios.get("/api/division/divisionlocations")
      .then(response => {
        console.log(response);
        this.mailroomlocations = response.data.divisions.division_locations;
        if(this.mailroomlocations.length > 0){
          if(response.data.divisions.default_mailroom_location_id){
            this.bookingdetail.mailroom_location = response.data.divisions.default_mailroom_location_id;
          }else{
            this.bookingdetail.mailroom_location = response.data.divisions.division_locations[0].id;
          }
        }
        this.emailtemplates = response.data.divisions.email_templates;
        if(this.mailroomlocations.length > 0 && this.emailtemplates.length > 0){
          this.setDefaultEmailTemplate();
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    setDefaultEmailTemplate(){
      let mailroomlocation = this.mailroomlocations.find(element => element.id == this.bookingdetail.mailroom_location);
      if(this.emailtemplates.length > 0){
        if(mailroomlocation){
          this.bookingdetail.email_template = mailroomlocation.default_mailroom_email_template_id;
        }else{
          this.bookingdetail.email_template = this.emailtemplates[0].id;
        }
      }
    },
    fetchDeliveryLocation(){
      if(this.bookingdetail.delivery_department && this.bookingdetail.delivery_branch){
        this.axios.get("/api/location/getlocationByid/"+this.bookingdetail.delivery_department+"/"+this.bookingdetail.delivery_branch)
        .then(response => {
          console.log(response);
          if(response.data.locations){
            this.deliverylocations = response.data.locations.locationdeptbranch;
            if(this.deliverylocations.length > 0){
              if(response.data.locations.default_location_id){
                this.bookingdetail.delivery_location = response.data.locations.default_location_id;
              }else{
                this.bookingdetail.delivery_location = response.data.locations.locationdeptbranch[0].id;
              }
            }else{
              this.bookingdetail.delivery_location = "";
            }
          }else{
            this.deliverylocations = [];
            this.bookingdetail.delivery_location = "";
          }
        })
        .catch(error => {
          console.log(error);
        });
      }else{
        this.deliverylocations = [];
        this.bookingdetail.delivery_location = "";
      }
    },
    fetchAddresses(){
      this.axios.post("api/address/list")
        .then(response => {
          this.address_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },
    submit() {
      this.$refs.observer.validate();
      this.save_btn.disabled = true;
      this.save_btn.value = "SAVING...";
      if(this.$route.params.id){
        this.axios.post("/api/courierbooking/createupdatebookingdetails/"+ this.$route.params.id, this.bookingdetail)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$router.push({ path : '/pages/ViewPending' });
          this.save_btn.disabled = false;
          this.save_btn.value = "SAVE";
        })
        .catch(error =>{
          console.log(error);
          this.save_btn.disabled = false;
          this.save_btn.value = "SAVE";
        });
      }else{
        let url = "/api/courierbooking/createupdatebookingdetails";
        if(this.booking_id){
          url += ("/"+this.booking_id);
          console.log(url);
        }
        this.axios.post(url, this.bookingdetail)
          .then(response => {
            this.toast.success(response.data.msg);
            if(this.booking_id){
              this.$router.push({ path : '/pages/ViewPending' });
            }else{
              this.fetched_booking = response.data.booking_details;
            }
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE";
          })
          .catch(error => {
            console.log(error);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE";
          });
      }
    }
  },
  watch:{
    'bookingdetail.delivery_branch'(){
      this.fetchDepartment();
    },
    'bookingdetail.delivery_department'(){
      this.fetchDeliveryLocation();
    }
  }
};
</script>
<style lang="scss">
</style>