<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Department Location Name</th>
          <th>Department Location Code</th>
          <!-- <th>Inbound Location</th>
          <th>Inbound Location Reference</th>
          <th>Outbound Location</th>
          <th>Outbound Location Reference</th> -->

          <th>Action</th>
        </tr>
        <tr v-for="(data,i) in department" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Name Character Limit 30" v-model="data.department_name" :disabled="!data.is_edit_mode" :maxlength="max" />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Code Character Limit 30" v-model="data.department_code" :disabled="!data.is_edit_mode" :maxlength="max" />
            </div>
          </td>
         <!--  <td colspan="4" class="not_padded">
            <div class="inner_rows">
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="cancelEditable" />
                </div>
              </div>
            </div>

            <div class="inner_rows">
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="cancelEditable" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="cancelEditable" />
                </div>
              </div>
            </div>
          </td> -->
          <td class>
            <a class="edit_icon" title="EDIT" @click="departmentlocation_id = data.id,is_editable=true"></a>
            <a class="delete_icon" title="DELETE" @click="deleteDepartment(data.id)"></a>
            <a class="view_icon" title="VIEW" @click="departmentlocation_id = data.id, is_editable=false"></a>
          </td>
        </tr>
      </table>
    </div>

    <div class="black-overlay" v-if="departmentlocation_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <DepartmentLocationPopup v-if="departmentlocation_id" :departmentlocation_id="departmentlocation_id" @closeModal="fetchDepartment" :is_editable="is_editable" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import DepartmentLocationPopup from "./DepartmentLocation_EditPopup.vue";
export default {
  name: "Department",
  components: {
    DepartmentLocationPopup
  },
  data() {
    return {
      max:30,
      department:[],
      departmentlocation_id : ""
    };
  },
  created(){
    this.fetchDepartment();
  },
  methods: {
    fetchDepartment(){
      this.departmentlocation_id = '';
      this.axios.get("/api/department/view")
      .then(response => {
          this.department = response.data.department.map(element => {
            element.is_edit_mode = false;
            return element;
          });
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    deleteDepartment(id) {
      if (confirm('Are you sure you want to delete this department?')) {
          this.axios.delete("/api/department/delete/" + id)
          .then(response => {
              this.toast.success(response.data.msg);
              this.fetchDepartment();
          })
          .catch(error => {
            console.log('error', error.response);
            this.toast.error();
          });;
      }
    },
    cancelDepartment() {
      this.fetchDepartment();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1400px;
    padding-right: 20px;
  }
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 14.2%;
}
.jobs_table.sp2 > table tr th:last-child,
.jobs_table.sp2 > table tr td:last-child {
  min-width: 260px;
}
.jobs_table.sp2 > table tr td.not_padded {
  padding: 0;
}
.inner_rows {
  width: 100%;
}
.inner_row_cells {
  width: 25%;
  float: left;
  border-right: 1px solid #ccc;
  padding: 5px 2px 0 2px;
}
.inner_rows:last-child .inner_row_cells {
  padding-bottom: 5px;
}
.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
.edit_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_edit.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.view_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_view.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
</style>