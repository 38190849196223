var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',[_c('div',{staticClass:"form_section1"},[_c('table',[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Carrier Name\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Name Character Limit 30","maxlength":_vm.max2},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})])]}}],null,true)})],1),_c('td',{staticClass:"rowspanned_4_chk",attrs:{"rowspan":"4","colspan":"2"}},[_c('ValidationProvider',{attrs:{"name":"dispatch","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Mode of Dispatch\n                    "),_c('span',[_vm._v("*")])]),_c('div',{staticClass:"chk_group"},_vm._l((_vm.dispatches),function(dispatch,i){return _c('label',{key:'dispatch'+i,class:errors[0] ? 'warning-border':''},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mode_of_dispatch),expression:"mode_of_dispatch"}],attrs:{"type":"checkbox"},domProps:{"value":dispatch.id,"checked":Array.isArray(_vm.mode_of_dispatch)?_vm._i(_vm.mode_of_dispatch,dispatch.id)>-1:(_vm.mode_of_dispatch)},on:{"change":function($event){var $$a=_vm.mode_of_dispatch,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=dispatch.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.mode_of_dispatch=$$a.concat([$$v]))}else{$$i>-1&&(_vm.mode_of_dispatch=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.mode_of_dispatch=$$c}}}}),_c('span',[_vm._v(_vm._s(dispatch.name))])])}),0)])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Carrier Code\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.carrier_code),expression:"carrier_code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Carrier code Character Limit 30","maxlength":_vm.max2},domProps:{"value":(_vm.carrier_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.carrier_code=$event.target.value}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"description","rules":"required|max:70","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Description\n                    "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],class:errors[0] ? 'warning-border':'',attrs:{"placeholder":"Type Here Character Limit 70","maxlength":_vm.max},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"logo","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Upload Logo\n                    "),_c('span',[_vm._v("*")])]),_c('label',{staticClass:"user_profile_upload "},[_c('span',[_vm._v("Choose a File")]),_c('input',{ref:"logo",class:errors[0] ? 'warning-border':'',attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.uploadFile}}),(_vm.logo != '' )?_c('span',[_vm._v("\n                      "+_vm._s(_vm.logoname)+"\n                    ")]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"rate","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Upload Rate Card\n                    "),_c('span',[_vm._v("*")])]),_c('label',{staticClass:"user_profile_upload"},[_c('span',[_vm._v("Choose a File")]),_c('input',{ref:"rate_card",class:errors[0] ? 'warning-border':'',attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.uploadFile}}),(_vm.rate_card != '' )?_c('div',[_vm._v("\n                      "+_vm._s(_vm.ratecardname)+"\n                    ")]):_vm._e()])])]}}],null,true)})],1)])])])])]}}])}),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_save",on:{"click":_vm.submit}},[_vm._v("Save")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }