<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">MAILROOM REPORTS</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose a Report Type
                  <span></span>
                </label>
                <select v-model="post_data.booking_type">
                  <option value="Mailroom">Inbound Scan Detail Report</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Type of Goods
                  <span></span>
                </label>
                <select v-model="post_data.type_of_good" @change="resetFields">
                  <option value="all">All</option>
                  <option
                    v-for="(good,i) in goods"
                    :key="'goods'+i"
                    :value="good.type"
                  >{{good.type}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Carrier
                  <span></span>
                </label>
                <select v-model="post_data.delivery_provider" @change="fetchService">
                  <option value="all">All</option>
                  <option
                    v-for="(carrier,i) in carriers"
                    :key="'carriers'+i"
                    :value="carrier.name"
                  >{{carrier.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Service
                  <span></span>
                </label>
                <select v-model="post_data.delivery_service" @change="resetFields">
                  <option value="all">All</option>
                  <option
                    v-for="(service,i) in services"
                    :key="'services'+i"
                    :value="service.name"
                  >{{service.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby" @change="resetFields">
                  <option value="delivery_provider">Carrier</option>
                  <option value="delivery_service">Service</option>
                  <option value="type_of_good">Type Of Goods</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Registry Dispatch System Monthly Summary</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div
          class="despatch_summary_table_container"
          v-for="(report,key,i) in reports"
          :key="'report_container_'+i"
        >
          <h2>{{key}}</h2>
          <table>
            <tr>
              <td>Bar Code</td>
              <td>Sender Name</td>
              <td>Receiver</td>
              <td>Goods Description</td>
              <td>Country</td>
              <td>Type Of Goods</td>
              <td>Service</td>
              <td>Carrier</td>
              <td>Weight</td>
              <td>Pieces</td>
              <td>Mail Room Location</td>
              <td>Delivery or Pickup Location</td>
              <td>Scanned Date</td>
              <td>Current Status</td>
              <td>Date and Time</td>
            </tr>
            <tr v-for="(courier,j) in report" :key="'courier_'+i+j">
              <td>
                <img class="bar_img" src="../../assets/temp_bar3.png" />
                <div class="bar_number">{{courier.booking_prefix}}{{courier.booking_id}}</div>
              </td>
              <td>{{courier.requester_name}}</td>
              <td>{{courier.delivery_name}}</td>
              <td>{{courier.goods_description}}</td>
              <td>{{courier.delivery_country}}</td>
              <td>{{courier.type_of_good}}</td>
              <td>{{courier.delivery_service}}</td>
              <td>{{courier.delivery_provider}}</td>
              <td>{{courier.item_weight}}</td>
              <td>{{courier.item_qty}}</td>
              <td>{{courier.mailroom_location}}</td>
              <td>{{courier.delivery_location}}</td>
              <td>{{courier.scanned_date}}</td>
              <td>{{courier.status}}</td>
              <td>{{courier.scanned_date}} {{courier.scanned_time}}</td>
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsMailroom",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      goods: [],
      services: [],
      couriers: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        booking_type: "Mailroom",
        type_of_good: "all",
        delivery_provider: "all",
        delivery_service: "all",
        groupby: "type_of_good"
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "m-d-Y",
          altInput: true
      },
      show_data: false
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Reports", "Mailroom Report"]);
    this.api_url = process.env.VUE_APP_API_URL;
    this.fetchCarrier();
    this.fetchGood();
    this.fetchService();
  },
  methods: {
    dateChanged(daterange) {
      this.resetFields();
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchCarrier() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchService() {
      this.resetFields();
      this.services = [];
      this.post_data.service = "all";
      if (this.post_data.delivery_provider != "") {
        this.axios
          .get("/api/provider/services/name/" + this.post_data.delivery_provider)
          .then(response => {
            console.log(response);
            this.services = response.data.services;
          })
          .catch(error => {
            this.toast.error();
          });
      }
    },
    fetchGood() {
      this.axios
        .get("/api/goods/")
        .then(response => {
          this.goods = response.data.goods;
          console.log(this.goods);
        })
        .catch(error => {
          this.toast.error();
        });
    },
    resetFields() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      this.resetFields();
      this.axios
        .post("/api/reports/getmailroomreports", this.post_data)
        .then(response => {
          this.show_data = true;
          console.log(response);
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getmailroomreports/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 16.6%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>