<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="3">
                  <div class="colspan_flex_adj">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch Name
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Branch Name Character Limit 30" v-model="unit.name" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" />
                    </div>
                  </ValidationProvider>

                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch Code
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Branch Code Character Limit 30" v-model="unit.branch_id" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" />
                    </div>
                  </ValidationProvider>
                  </div>
                </td>
              </tr>
              <tr>
                <!-- <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Division
                      <span>*</span>
                    </label>
                    <select v-model="unit.division_id" @click="fetchDivision">
                      <option value="">Select</option>
                      <option v-for="(division,i) in divisions" :key="'division'+i" :value="division.id">{{division.name}}</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub
                      <span>*</span>
                    </label>
                    <select v-model="unit.hub_id" @click="fetchHub">
                      <option value="">Select</option>
                      <option v-for="(hub,i) in hubs" :key="'hub'+i" :value="hub.id">{{hub.name}}</option>
                    </select>
                  </div>
                </td> -->
                <td>
                  <ValidationProvider name="hub" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                     Hub/Mailroom
                      <span>*</span>
                    </label>
                    <select v-model="unit.division_id" @click="fetchDivision" :class="errors[0] ? 'warning-border':''">
                      <option value="">Select</option>
                      <option v-for="(division,i) in divisions" :key="'division'+i" :value="division.id">{{division.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="cost" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Cost Center
                      <span>*</span>
                    </label>
                    <select v-model="unit.cost_center_id" @click="fetchCostCenter" :class="errors[0] ? 'warning-border':''">
                      <option value="">Select</option>
                      <option v-for="(cost,i) in cost_centers" :key="'cost'+i" :value="cost.id">{{cost.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="colspan_flex_adj">
                  <ValidationProvider name="address_1" rules="required|max:60" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address 1
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Address 1 Character Limit 60" v-model="unit.address_1" :maxlength="max"  :class="errors[0] ? 'warning-border':''" />
                    </div>
                  </ValidationProvider>

                  <ValidationProvider name="address_2" rules="required|max:60" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address 2
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Address 2 Character Limit 60" v-model="unit.address_2" :maxlength="max"  :class="errors[0] ? 'warning-border':''" />
                    </div>
                  </ValidationProvider>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                    
                    </label>
                    <input type="text" placeholder="Suburb Character Limit 30" v-model="unit.suburb" :maxlength="max1" class />
                  </div>
                </td>
                <td>
                  <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="City Character Limit 30" v-model="unit.city" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" />
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      State
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="State Character Limit 30" v-model="unit.state" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" />
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="country" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Country Character Limit 30" v-model="unit.country" :maxlength="max1"  :class="errors[0] ? 'warning-border':''" />
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="postal code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Postal Code Character Limit 10" v-model="unit.postal_code" :maxlength="max3"  :class="errors[0] ? 'warning-border':''" />
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="phone" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone Number
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Phone Character Limit 20" v-model="unit.phone" :maxlength="max2"  :class="errors[0] ? 'warning-border':''" />
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Unit",
  data(){
    return {
      max1:30,
      max:60,
      max2:20,
      max3:10,
      unit:{
        name: "",
        branch_id: "",
        cost_center_id: "",
        division_id: "",
        hub_id: "",
        address_1: "",
        address_2: "",
        suburb: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        phone: ""
      },
      cost_centers:[],
      divisions:[],
      hubs:[]
    }
  },
  created(){    
    this.fetchCostCenter();
    this.fetchDivision();
    this.fetchHub();
},
  methods: {
    fetchCostCenter(){
      this.axios.get("/api/cost/")
      .then(response => {
        this.cost_centers = response.data.cost_centers;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    fetchDivision(){
      this.axios.get("/api/division/")
      .then(response => {
        this.divisions = response.data.divisions;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    fetchHub(){
      this.axios.get("/api/hub/")
      .then(response => {
        this.hubs = response.data.hubs;
      })
      .catch(error => {
        this.toast.error(error);
      });
    },
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/branch/",this.unit)
        .then(response => {
          this.toast.success(response.data.msg);
            this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
</style>