<template>
	<div class="help-div">
		<h4><span>Helpdesk </span>-  Support Email Address: </h4>
		<div class="email_div">
			<a href="mailto:phil-helpdesk-support@sunlife.com">phil-helpdesk-support@sunlife.com</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Support',
		data() {
			return {
				pageDepth: ['Support'],
			}
		},
        created() {
            this.$emit("breadCrumbData",["Support"]);
        },
		beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.$root.$emit('pageHierarchy', vm.pageDepth);
            });
        },
	}
</script>

<style lang="scss">
	.help-div {
		margin-top: 100px;
		h4 {
			font-size: 28px;
			text-align: center;
			font-weight: 900;
			margin-left: 0%;
			span {
				color: #ffd10a;
			}
		}
		.email_div{
			padding:20px 0; font-size:22px; font-weight: 400;
			 text-decoration: none; text-align: center;
			 a{color:#333;
			 	&:hover{
				color:#ffd10a;
				}
			}
			
		}
	}
</style>