import { render, staticRenderFns } from "./ReportsMailroom.vue?vue&type=template&id=aa56bb4e&scoped=true&"
import script from "./ReportsMailroom.vue?vue&type=script&lang=js&"
export * from "./ReportsMailroom.vue?vue&type=script&lang=js&"
import style0 from "./ReportsMailroom.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ReportsMailroom.vue?vue&type=style&index=1&id=aa56bb4e&scoped=true&lang=css&"
import style2 from "./ReportsMailroom.vue?vue&type=style&index=2&id=aa56bb4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa56bb4e",
  null
  
)

export default component.exports