<template>
  <div class="common_sub_container">
    <div class="dashboard_row">
      <div class="dashboard_block dashboard_block1">
        <loader v-if="loaders.profile_info_loader" size="60" />
        <div class="logo_div" v-if="!loaders.profile_info_loader">
          <img :src="profile.avatar" />
        </div>
        <div class="content_div" v-if="!loaders.profile_info_loader">
          <span class="name">{{profile.name}}</span>
          <span class="phone">
            <i class="material-icons">phone</i>
            {{profile.phone}} / {{profile.mobile}}
          </span>
          <span class="mail">
            <i class="material-icons">mail</i>
            {{profile.email}}
          </span>
          <span class="address">
            <i class="material-icons">location_on</i>
            <em>
              {{profile.address_1}},
              {{profile.address_2}},
              {{profile.city}},{{profile.state}},
              {{profile.suburb}}, {{profile.postal_code}},
              {{profile.country}}
            </em>
          </span>
        </div>
      </div>
      <div class="dashboard_block dashboard_block2">
        <h4>Track Shipment</h4>
        <div class="logo_row">
          <div class="logo_div">
            <img src="../../assets/dash_logo.png" />
          </div>
          <div class="search_div">
            <input
              type="text"
              v-model="bookingId"
              placeholder="Search here"
              @keyup.enter="$refs.search_booking.click()"
            />
            <a class="bt_search" ref="search_booking" @click="submit">Search</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard_row">
      <div class="second_row_cover1">
        <div class="dashboard_block dashboard_block3">
          <h4>View Pending</h4>
          <loader v-if="loaders.booking_reports_loader" size="60" />
          <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader">
            <div class="pending_block" v-if="!loaders.booking_reports_loader" @click="redirectTo('/pages/ViewPending',{jobType:'encode'})">
              <img src="../../assets/dash_encode.png" />
              <p class="total">Total</p>
              <p class="total numbers clr4">{{count.encode}}</p>
              <p class="total category">ENCODE</p>
            </div>
            <div class="pending_block" @click="redirectTo('/pages/ViewPending',{jobType:'Mailroom'})">
              <img src="../../assets/dash_mailroom.png" />
              <p class="total">Total</p>
              <p class="total numbers clr1">{{count.mailroom}}</p>
              <p class="total category">MAILROOM</p>
            </div>
            <div class="pending_block" v-if="!loaders.booking_reports_loader"  @click="redirectTo('/pages/ViewPending',{jobType:'Batch Upload'})">
              <img src="../../assets/dash_courier.png" />
              <p class="total">Total</p>
              <p class="total numbers clr2">{{count.courier}}</p>
              <p class="total category">COURIER</p>
            </div>
            <div class="pending_block" v-if="!loaders.booking_reports_loader">
              <img src="../../assets/dash_transport.png" />
              <p class="total">Total</p>
              <p class="total numbers clr3">0</p>
              <p class="total category">TRANSPORT</p>
              <div class="overlay">CURRENTLY NOT AVAILABLE</div>
            </div>
          </div>
        </div>
        <div class="dashboard_block dashboard_block4">
          <div class="dashboard_header">
            <h4>Booking Reports</h4>
            <select v-model="chart_filter" @change="generateChart">
              <option value="all">All</option>
              <option value="encode">Encode</option>
              <option value="mailroom">Mailroom</option>
            </select>
          </div>
          <div class="chart_container">
            <loader v-if="loaders.chart_loader"/>
            <line-chart :chartdata="chart_data" :options="options" v-if="!loaders.chart_loader"/>
          </div>
        </div>
      </div>
      <div class="second_row_cover2">
        <div class="dashboard_block dashboard_block5">
          <h4>Recent Activities</h4>
          <loader v-if="loaders.recent_activities_loader" size="60" />
          <div class="activity_list" v-if="!loaders.recent_activities_loader">
            <div class="activity_block" v-for="(recent,i) in recents" :key="i">
              <div
                class="activity_time"
              >{{ moment(recent.created_at, "DDMMYYYY h:mm:ss").fromNow() }}</div>
              <div class="activity">{{recent.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "./LineChart";

export default {
  name: "dashboard",
  components:{ LineChart },
  data() {
    return {
      profile: {
        name: "",
        email: "",
        company: "",
        branch: "",
        phone: "",
        cost_center_id: "",
        avatar: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        suburb: "",
        postal_code: "",
        country: ""
      },
      count: {
        mailroom: 0,
        courier: 0,
        encode:0
      },
      bookingId: "",
      recents: [],
      chart_filter:"all",
      chart_data:{
        labels:[],
        datasets: [{
            label: 'No of Bookings',
            data: [],
            backgroundColor: 'rgba(255, 209, 10, 0.6)',
            borderColor: '#000',
            pointBorderColor:'#000',
            borderWidth: 1
        }]
      },
      options:{
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value;}},
            }
          }]
        },
        responsive:true,
        maintainAspectRatio:false
      },
      loaders: {
        profile_info_loader: true,
        courier_count_loader: true,
        recent_activities_loader: true,
        booking_reports_loader: true,
        chart_loader:true
      }
    };
  },
  async created() {
    this.$emit("breadCrumbData", []);
    this.loading = true;
    this.fetchUserData();
    this.fetchBookingCount();
    this.recentActivity();
    this.generateChart();
  },
  methods: {
    async fetchUserData() {
      this.loaders.profile_info_loader = true;
      try {
        let response = await this.axios.get("/api/dashboard/user/data");
        this.profile.name = response.data.msg.name;
        this.profile.email = response.data.msg.email;
        this.profile.phone = response.data.msg.phone;
        this.profile.mobile = response.data.msg.mobile;
        this.profile.address_1 = response.data.msg.address_1;
        this.profile.address_2 = response.data.msg.address_2;
        this.profile.city = response.data.msg.city;
        this.profile.state = response.data.msg.state;
        this.profile.suburb = response.data.msg.suburb;
        this.profile.postal_code = response.data.msg.postal_code;
        this.profile.country = response.data.msg.country;
        this.profile.avatar = response.data.msg.avatar;
      } catch (e) {
        console.log(e);
      }
      this.loaders.profile_info_loader = false;
    },
    async recentActivity() {
      this.loaders.recent_activities_loader = true;
      try {
        let response = await this.axios.get("/api/dashboard/user/recent");
        this.recents = response.data.msg;
      } catch (e) {
        console.log(e);
      }
      this.loaders.recent_activities_loader = false;
    },
    redirectTo(to,data){
      if(this.auth().user_type != 'customer_service_user'){
        localStorage.viewPending = JSON.stringify(data);
        this.$router.push(to);
      }
    },
    async fetchBookingCount() {
      this.loaders.booking_reports_loader = true;
      try {
        let response = await this.axios.get("/api/dashboard/user/bookingCount");
        this.count.mailroom = response.data.MailRoom;
        this.count.courier = response.data.Courier;
        this.count.encode = response.data.Encode;
      } catch (e) {
        console.log(e);
      }
      this.loaders.booking_reports_loader = false;
    },
    async generateChart(){
      this.loaders.chart_loader = true;
      let response = await this.axios.get("api/chart/"+ this.chart_filter);
      try{
          let dates = [
            this.moment().subtract(6,'d').format('YYYY-MM-DD'),
            this.moment().subtract(5,'d').format('YYYY-MM-DD'),
            this.moment().subtract(4,'d').format('YYYY-MM-DD'),
            this.moment().subtract(3,'d').format('YYYY-MM-DD'),
            this.moment().subtract(2,'d').format('YYYY-MM-DD'),
            this.moment().subtract(1,'d').format('YYYY-MM-DD'),
            this.moment().format('YYYY-MM-DD')
          ];
          let data = {};
          response.data.data.forEach(element => {
              data[element.request_date] = element.count;
          });
          this.chart_data.labels = dates.map(element => {
            return this.moment(element).format("dddd");
          });
          this.chart_data.datasets[0].data = dates.map(element => {
              if(data[element]){
                return data[element];
              }else{
                return 0;
              }
          });
      }catch(e){
        console.log(e);
      }
      this.loaders.chart_loader = false;
    },
    submit() {
      localStorage.setItem(
        "track_trace_filter",
        JSON.stringify({
          booking_id: this.bookingId
        })
      );
      this.$router.push("/pages/TrackTrace");
    }
  }
};
</script>
<style  lang="scss" >
.dashboard_row {
  width: 100%;
  display: flex;
  padding: 20px 0;

  .dashboard_block {
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 25px;
    border: 1px solid #efefef;
    h4 {
      font-size: 20px;
      font-weight: Normal;
      color: #333;
      padding-bottom: 10px;
    }
    &.dashboard_block1 {
      border-top: 5px solid #f8cb00;
      width: 50%;
      height: 250px;
      margin-right: 20px;

      display: flex;
      .logo_div {
        width: 150px;
        height: 150px;
        flex-shrink: 0;
        background: #ccc;
      }
      .content_div {
        width: 100%;
        height: auto;
        padding: 10px;
        color: #666;
        span {
          display: flex;
          padding-bottom: 10px;
          i {
            position: relative;
            top: 3px;
            color: #ff5050;
          }
          &.name {
            font-weight: bold;
            padding-left: 10px;
            font-size: 18px;
          }
          em {
            display: inline-block;
            font-style: normal;
          }
        }
      }
    }
    &.dashboard_block2 {
      border-top: 5px solid #f54442;
      width: 50%;
      height: 250px;
      margin-left: 20px;
      .logo_row {
        display: flex;
        padding: 20px 0;
        height: 155px;
        .logo_div {
          width: 200px;
          height: auto;
          img {
            width: auto;
            height: 100%;
          }
        }
        .search_div {
          min-width: 300px;
          width: calc(100% - 250px);
          height: auto;
          padding: 40px 20px;
          background: #ffd30a;
          input[type="text"] {
            width: calc(100% - 80px);
            height: 40px;
            background: #fff;
            border: 1px solid #ccc;
            padding: 0 10px;
            display: inline-block;
          }
          .bt_search {
            width: 80px;
            height: 40px;
            background: #333;
            color: #fff;
            display: inline-block;
            text-align: center;
            line-height: 40px;
          }
        }
      }
    }
    &.dashboard_block3 {
      border-top: 5px solid #2ec675;
      width: 100%;
      height: 350px;
      margin-bottom: 40px;
    }
    &.dashboard_block4 {
      border-top: 5px solid #29abcb;
      width: 100%;
      height: 350px;
      .dashboard_header{
        width:100%;
        display:flex;
        justify-content: space-between;
      }
      .chart_container{
        width:100%;
      }
    }
    &.dashboard_block5 {
      border-top: 5px solid #666;
      width: 100%;
      height: 735px;
      overflow-y: scroll;
    }
  }
  .second_row_cover1 {
    width: 70%;
    margin-right: 20px;
  }
  .second_row_cover2 {
    width: 30%;
    margin-left: 20px;
  }
  .pending_blocks_row {
    display: flex;
    height: 240px;

    margin: auto;
    justify-content: space-around;
    .pending_block {
      width: 30%;
      height: auto;
      text-align: center;
      position: relative;
      padding: 10px;
      cursor:pointer;
      .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        font-size: 16px;
        text-align: center;
        color: #fff;
        display: none;
        justify-content: center;
        flex-direction: column;
      }
      &:hover .overlay {
        display: flex;
      }
      img {
        display: block;
        width: 105px;
        height: 105px;
        margin: auto;
      }
      p.total {
        padding: 5px 0;
        text-align: center;
        font-size: 18px;
        &.numbers {
          font-size: 32px;
          font-weight: 600;
          &.clr1 {
            color: #ff5050;
          }
          &.clr2 {
            color: #ff9600;
          }
          &.clr3 {
            color: #ffd10a;
          }
          &.clr4 {
            color: #43a9f5;
          }
        }
        &.category {
          font-size: 24px;
          color: #333;
        }
      }
    }
  }
  .activity_list {
    padding: 10px;
    .activity_block {
      padding-bottom: 20px;
      .activity_time {
        color: #aaa;
        font-size: 14px;
        padding-left: 20px;
        background: url(../../assets/bull_dash.png) no-repeat;
        background-position: left 0 top 50%;
      }
      .activity {
        color: #333;
        font-size: 16px;
        padding-left: 20px;
        background: url(../../assets/bull_line.png) repeat-y;
        background-position: left 0 top 50%;
      }
    }
  }
}

#line-chart{
  height:280px;
}
</style>