<template>
  <div class="common_sub_container">
    <h2>USER PROFILE & COURIER SETTINGS</h2>
    <div class="two_profile_tabs">
      <a @click="showTab1=true,showTab2=false" v-bind:class="{ active: showTab1 }">User Profile</a>
      <a @click="showTab2=true,showTab1=false" v-bind:class="{ active: showTab2 }">Courier Settings</a>
    </div>
    <div class="two_profile_tabs_wrapper">
      <UserProfileTab v-if="showTab1" />
      <CourierSettingsTab v-if="showTab2" />
    </div>
  </div>
</template>
<script>
import UserProfileTab from "./UserProfileTab.vue";
import CourierSettingsTab from "./CourierSettingsTab.vue";
export default {
  name: "ProfileSettings",
  components: {
    UserProfileTab,
    CourierSettingsTab
  },
  created(){
    this.$emit("breadCrumbData",["Profile And Settings"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  }
};
</script>
<style  lang="scss" >
.two_profile_tabs {
  height: 42px;
  border-bottom: 2px solid #ccc;
  a {
    display: inline-block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #eee;
    color: #666;
    font-size: 18px;
    text-align: center;
    margin-right: 10px;
    &.active {
      background: #323232;
      color: #fff;
    }
  }
}
</style>