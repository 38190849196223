<template>
  <div class="pop_container">
    <div class="title_area">UPDATE STATUS <a @click="closeModal" class="close">X</a></div>
    <div class="body_area">
      <div class="form_section_generate_slip">
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <table>
            <tr>
              <td colspan="3">
              <ValidationProvider name="status" rules="required" v-slot="{ errors }" slim>
                <select v-model="statuscourier.status" :class="errors[0] ? 'warning-border':''">
                  <option value="">Select status</option>
                  <option value="Created">Created</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Not Home">Not Home</option>
                  <option value="PickedUp">PickedUp</option>
                  <option value="Received">Received</option>
                  <option value="RTS">RTS</option>
                  <option value="Transmitted">Transmitted</option>
                  <option value="WC">WC</option>
                </select>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td>
                <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Name Character Limit 30" v-model="statuscourier.sender_or_receiver" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                </div>
                </ValidationProvider>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Date
                    <span>*</span>
                  </label>
                  <ValidationProvider name="date" rules="required" v-slot="{ errors }" slim>
                    <div :class="errors[0] ? 'border-danger':''">
                  <flat-pickr
                    v-model="statuscourier.date"
                    :config="configDate"
                    placeholder="Select Date"
                    name="birthdate" 
                  ></flat-pickr>
                  </div>
                  </ValidationProvider>
                  </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Time
                    <span>*</span>
                  </label>
                  <ValidationProvider name="time" rules="required" v-slot="{ errors }" slim>
                  <flat-pickr
                    v-model="statuscourier.time"
                    :config="configTime"
                    placeholder="Select Time"
                    name="birthtime"
                    :class="errors[0] ? 'warning-border':''"
                  ></flat-pickr>
                  </ValidationProvider>
                </div>
                
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <ValidationProvider name="comment" rules="required|max:100" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Comment
                    <span>*</span>
                  </label>
                  <textarea placeholder="Comment here Character Limit 100" v-model="statuscourier.note" :maxlength="max1" :class="errors[0] ? 'warning-border':''"></textarea>
                </div>
                </ValidationProvider>
              </td>
            </tr>
          </table>
        </ValidationObserver>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="submit">Update</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  name: "popUpdateStatus",
  components: {
    flatPickr
  },
  data() {
    return {
      max:30,
      max1:100,
      date: this.moment(new Date()).format("YYYY-MM-DD"),
      time: this.moment(new Date()).format("HH:mm:ss"),
      modalStatus: false,
      enableTime: true,
      configTime: {
        enableTime: true,
        noCalendar: true,         
        dateFormat: "H:i",
        time_24hr: true
      },
      configDate: {
       format: "d-m-Y",
        altFormat: "m-d-Y",
        altInput: true,
        altInputClass:""
      },
      statuscourier:{
        status:"",
        date:"",
        time:"",
        sender_or_receiver:"",
        note:""
      }
    };
  },

 methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    submit(){
      if(this.type != 'all'){
        if(confirm("Are you sure you want to update this status?")){
          this.updateStatus();
        }
      }else{
        this.updateStatus();
      }
    },
    updateStatus(){
        this.$refs.observer.validate();
        this.axios.post("/api/courier/"+this.booking_id+"/status",this.statuscourier)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit("closeModal");
        })
        .catch(error =>{
          console.log(error);
        });
    }
  },
  props: {
    booking_id:String,
    type:{
      type:String,
      default:"all"
    }
  }
};
</script>
<style lang="scss">
.pop_container {
  height: auto;
}
.form_section_generate_slip input[type="text"] {
  width: 100%;
}
.border-danger{
  border: 1px solid #ff3333 !important;
}

.border-danger input{
  border:none !important;
}
</style>