import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import http from "./http.js";
Vue.prototype.axios = http;

import Toasted from "vue-toasted";
Vue.use(Toasted);

import "material-icons/iconfont/material-icons.css";

import VueBarcode from '@chenfengyuan/vue-barcode';

Vue.component(VueBarcode.name, VueBarcode);

import Toast from './mixins/toast.js';
Vue.prototype.toast = Toast;

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import Auth from "./mixins/auth.js";

import moment from "moment";
Vue.prototype.moment = moment;

import loadingOverlay from "./components/pages/loadingOverlay.vue";
Vue.component("loader", loadingOverlay);

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.mixin({
  methods: {
    auth: Auth
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
