import Vue from "vue";
import toast from "../toast.scss";

var Toast = new Vue({
  methods: {
    error(msg) {
      this.$toasted.show(msg, {
        theme: "primary",
        position: "bottom-right",
        duration: 3000,
        iconPack: "material",
        className: ["error-toast"],
        icon: {
          name: "error"
        }
      });
    },
    success(msg) {
      this.$toasted.show(msg, {
        theme: "primary",
        position: "bottom-right",
        className: ["success-toast"],
        duration: 3000,
        iconPack: "material",
        icon: {
          name: "check"
        }
      });
    }
  }
});

export default Toast;