<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Center Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30" v-model="cost.name"  :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Center Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Code Character Limit 30" v-model="cost.code"  :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CostCenter",
  data(){
    return {
      max:30,
      cost:{
        name : "",
        code:""
      }
    }
  },
  methods: {
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/cost/",this.cost)
        .then(response => {
          this.toast.success(response.data.msg);
            this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
</style>