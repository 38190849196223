<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Type Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character limit 30" :class="errors[0] ? 'warning-border':''" v-model="goods.type" required="required" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                <td rowspan="4" colspan="2" class="rowspanned_4_chk">
                  <ValidationProvider name="dispatch" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Mode of Dispatch
                      <span>*</span>
                    </label>
                    <div class="chk_group" >
                      <label v-for="(dispatch,i) in dispatches" :key="'dispatch'+i" :class="errors[0] ? 'warning-border':''">
                        <input type="radio" name="mode_of_dispatch" v-model="goods.dispatch_mode" :value="dispatch.id" required="required"/>
                        <span>{{dispatch.name}}</span>
                      </label>
                    </div>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Weight
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" v-model="goods.weight" placeholder="Weight Character limit 30" class="dynamic_data_trigger" required="required" :maxlength="max"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Length
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Length Character limit 30" class="dynamic_data_trigger" v-model="goods.length" required="required" :maxlength="max" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Width
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Width Character limit 30" class="dynamic_data_trigger" v-model="goods.width" required="required" :maxlength="max"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Height
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Height Character limit 30" class="dynamic_data_trigger" v-model="goods.height" required="required" :maxlength="max"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      M
                      <sup>3</sup>
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="M3 Character limit 30" class="dynamic_data_trigger" v-model="goods.m3" required="required" :maxlength="max"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Cubic/KG
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Cubic/KG Character limit 30" class="dynamic_data_trigger" v-model="goods.cubic_kg" required="required" :maxlength="max"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label class="chk_label">
                    <span>Danger Good</span>
                    <input name="usertype" type="checkbox" class="chk_prefered" v-model="goods.is_dg" true-value="1" false-value="0" disabled/>
                  </label>
                  <label class="chk_label">
                    <span>Food</span>
                    <input name="usertype" type="checkbox" class="chk_prefered" v-model="goods.is_food" true-value="1" false-value="0" disabled/>
                  </label>
                  <label class="chk_label">
                    <span>Volume</span>
                    <input name="usertype" type="checkbox" class="chk_prefered" v-model="goods.volume" true-value="Y" false-value="N" disabled/>
                  </label>
                </td>
                <td colspan="2"></td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>
        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TypeOfGood",
  data(){
    return {
      max:30,
      isValidationAllowed: false,
      dispatches:[],
      goods:{
        type : "",
        mode_of_dispatch: [],
        dispatch_mode:"",
        volume:"N",
        weight:0,
        length:0,
        width:0,
        height:0,
        m3:0,
        cubic_kg:0,
        is_dg:"0",
        is_food:"0"
      }
    }
  },
  created() {
    this.fetchModeOfDispatch();
  },
  methods: {
    fetchModeOfDispatch(){
      this.axios.get("/api/dispatch/")
      .then(response => {
          this.dispatches = response.data.mode_of_dispatch;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    submit(){
      this.$refs.observer.validate();
      this.isValidationAllowed = true;
      this.goods.mode_of_dispatch = this.goods.dispatch_mode ? [this.goods.dispatch_mode] : "";
      this.axios.post("/api/goods/",this.goods)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style>
.rowspanned_4_chk {
  width: 40%;
  overflow-x: scroll;
}
.rowspanned_4_chk .chk_group {
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  height: 320px;
  overflow-y: scroll;
}
.rowspanned_4_chk .chk_group label {
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background: #efefef;
}
.rowspanned_4_chk .chk_group label:last-child {
  margin-bottom: 0;
}
.rowspanned_4_chk .chk_group label span {
  display: inline-block;
  color: #333;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  position: relative;
  top: -3px;
}
.rowspanned_4_chk .chk_group label input[type="checkbox"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  position: relative;
  top: 2px;
}
</style>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 20px 0 0 0;
}
.chk_label {
  display: inline-block;
  margin: 10px 20px 10px 10px;
  background: #fff;
  height: 40px;
  border: 1px solid #ccc;
}
.chk_label span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  top: 5px;
}
.chk_label input {
  display: inline-block;
  position: relative;
  top: 10px;
}
</style>