<template>
  <div class="popup_sub_container">
    <h2>VIEW / EDIT DEPARTMENT/LOCATION</h2>
    <i class="closeme" @click="closeModal">X</i>
    <section v-if="departmentlocation_id">
      <div class="jobs_table sp">
        <div class>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <section>
            <div class="form_section1">
              <table>
                <tr>
                  <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Department/Location Name
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Department Location Name Character Limit 30" v-model="department.department_name"  :class="errors[0] ? 'warning-border':''" :maxlength="max" :disabled="!is_editable"/>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Department/Location Code
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Department Location Code Character Limit 30" v-model="department.department_code"  :class="errors[0] ? 'warning-border':''" :maxlength="max" :disabled="!is_editable"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                  <ValidationProvider name="branch" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch
                        <span>*</span>
                      </label>
                      <select v-model="department.branch_id" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
                        <option value="">Choose Branch</option>
                        <option :value="branch.id" v-for="(branch,i) in branches" :key="'branch_'+i">
                          {{branch.name}}
                        </option>
                      </select>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  </td>
                </tr>
              </table>
              <div class="sub_form_section" v-for="(location,i) in department.location" :key="'location_container'+i">
                <div class="add_remove_btns" v-if="is_editable">
                  <a class="add_bt" @click="addLocationFields"></a>
                  <a class="remove_bt" v-if="department.location.length > 1" @click="removeLocationFields(location,i)"></a>
                </div>
                <table class="sub_table">
                  <tr>
                    <td>
                  <ValidationProvider name="Inbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Inbound Location
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Inbound Location Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location" :disabled="!is_editable" :maxlength="max"/>
                      </div>
                  </ValidationProvider>
                    </td>
                    <td>
                  <ValidationProvider name="Inbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Inbound Location Reference
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Inbound Location Reference Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location_ref" :disabled="!is_editable" :maxlength="max"/>
                      </div>
                  </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>
                  <ValidationProvider name="Outbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Outbound Location
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Outbound Location Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location" :disabled="!is_editable" :maxlength="max"/>
                      </div>
                  </ValidationProvider>
                    </td>
                    <td>
                  <ValidationProvider name="Outbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                        <label>
                          Outbound Location Reference
                          <span>*</span>
                        </label>
                        <input type="text" placeholder="Outbound Location Reference Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location_ref" :disabled="!is_editable" :maxlength="max"/>
                      </div>
                  </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                  <ValidationProvider name="Is Default" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                        <label>
                          Is Default
                          <span></span>
                        </label>
                        <input type="checkbox" v-model="location.is_default" :class="errors[0] ? 'warning-border':''" true-value="1" false-value="0" @click="setDefault(i)" :disabled="!is_editable"/>
                      </div>
                  </ValidationProvider>
                  </tr>
                </table>
              </div>
            </div>
          </section>
        </ValidationObserver>

          <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
            <a class="link_bt bt_save" @click="updateDepartment" :disabled="update_btn.disabled" v-if="is_editable">{{update_btn.value}}</a>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "EditBranch",
  created(){
    this.fetchDepartment();
    this.fetchBranches();
  },
  data() {
    return {
      max:30,
      department:{
        department_name : "",
        department_code:"",
        branch_id:"",
        location:[
          {
            inbound_location:"",
            inbound_location_ref:"",
            outbound_location:"",
            outbound_location_ref:"",
            is_default:0
          }
        ]
      },
      branches:[],
      update_btn:{
        disabled:false,
        value:"UPDATE"
      }
    };
  },
  methods:{
    closeModal() {
      this.$emit("closeModal");
    },
    fetchDepartment(){
      this.axios.get("api/department/showdepartment/"+this.departmentlocation_id)
      .then(response => {
        console.log(response);
        this.department.department_name = response.data.department.department_name;
        this.department.department_code = response.data.department.department_code;
        this.department.branch_id = response.data.department.location[0].branch_id ? response.data.department.location[0].branch_id : "";
        if(response.data.department.location[0].locationdeptbranch.length > 0){
          this.department.location = [];
        }

        response.data.department.location[0].locationdeptbranch.map(data => {
          data.is_default = (data.id == response.data.department.location[0].default_location_id) ? 1 : 0;
          return data;
        }).forEach(element => {
          this.department.location.push(element);
        });
        
      })
      .catch(error => {
        console.log(error);
      })
    },
    fetchBranches(){
      this.axios.get("api/branch/")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
  },
   addLocationFields(){
      this.department.location.push({
        inbound_location:"",
        inbound_location_ref:"",
        outbound_location:"",
        outbound_location_ref:"",
        is_default:0
      });
    },

    removeLocationFields(location,index){
        if(location.id){
          if (confirm('Are you sure you want to delete this location?')) {
              this.axios.delete("/api/department/deletedepartmentlocation/" + location.id)
              .then(response => {
                console.log(response);
                  this.toast.success(response.data.msg);
                  this.department.location.splice(index,1);
                  this.fetchDepartment();
              })
              .catch(error => {
                console.log(error);
              });
          }
        }else{
          this.department.location.splice(index,1);

        } 
        
    },
    setDefault(i){
      this.department.location.forEach(element => {
        element.is_default = 0;
      });
      this.department.location[i].is_default = 1;
    },
    updateDepartment(){
      this.$refs.observer.validate();
      this.update_btn.disabled = true;
      this.update_btn.value="UPDATING...";
      this.axios.put("api/department/edit/"+this.departmentlocation_id,this.department).then(response => {
        this.toast.success(response.data.msg);
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        this.closeModal();
      })
      .catch(error => {
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        console.log(error);
      });
    }
  },
  props:{
    departmentlocation_id:String,
    is_editable:{
      type:Boolean,
      default:true
    }
  }
};
</script>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}
.preferred_hide {
  display: none;
}
.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}
.chk_prefered:checked + hr + .preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #ffd10a;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
  border: 0;
}
.jobs_table table tr td {
  padding: 0;
  border: 0;
}
.default_checkbox{    
  width:22px;   
  height:22px   
}
</style>