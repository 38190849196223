<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
          <tr>
            <th>Carrier Name</th>
            <th>Service Name</th>
            <th>Service Code</th>
            <th>Type</th>

            <th>Action</th>
          </tr>
          <tr v-for="(service,i) in services" :key="i">
            <td>
              <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <select v-model="service.provider_id" :disabled="!service.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                  <option value>Select Carrier</option>
                  <option
                    v-for="(carrier,i) in carriers"
                    :key="'carriers'+i"
                    :value="carrier.id"
                  >{{carrier.name}}</option>
                </select>
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max"
                  :class="errors[0] ? 'warning-border':''"
                  v-model="service.name"
                  :disabled="!service.is_edit_mode"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input
                  type="text"
                  placeholder="Enter Character Limit 30" :maxlength="max"
                  :class="errors[0] ? 'warning-border':''"
                  v-model="service.code"
                  :disabled="!service.is_edit_mode"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <select v-model="service.type" :disabled="!service.is_edit_mode":class="errors[0] ? 'warning-border':''">
                  <option value>Select</option>
                  <option value="D">Domestic</option>
                  <option value="I">International</option>
                  <option value="M">Messenger</option>
                </select>
              </div>
              </ValidationProvider>
            </td>

            <td class>
              <a class="table_action_btn" title="DELETE" @click="deleteService(service.id)">DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="service.is_edit_mode"
                @click="cancelService()"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                v-if="service.is_edit_mode"
                @click="updateService(service)"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                v-if="!service.is_edit_mode"
                @click="service.is_edit_mode=true"
              >EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrierServices",
  components: {},
  data() {
    return {
      max:30,
      services: [],
      carriers: []
    };
  },
  created() {
    this.fetchCarriers();
    this.fetchServices();
  },
  methods: {
    fetchCarriers() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchServices() {
      this.axios
        .get("/api/service/")
        .then(response => {
          this.services = response.data.services.map(element => {
            element.is_edit_mode = false;
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateService(data) {
     this.$refs.observer.validate();
      this.axios
        .put("/api/service/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteService(id) {
      if (confirm("Are you sure you want to delete this Carrier Services?")) {
        this.axios
          .delete("/api/service/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchServices();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelService(){
      this.fetchServices();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>