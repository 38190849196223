<template>
  <div class="login_page">
    <div class="left_part"></div>
    <div class="right_part">
      <div class="logo_div">
        <img src="../assets/logo.png" />
      </div>
      <h2 class="sp">Sign in to your account</h2>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <div class="login_form_wrapper">
        <div class="form_label_wrapper">Your email address</div>
        <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
            slim
          >
        <div class="form_element_wrapper">
            <input
              type="text"
              v-model="user.email"
              placeholder="Enter email here"
              @keyup.enter="$refs.password.focus()"
              :class="errors[0] && show_error ? 'warning-border':''"
            />
            <p class="error-class" v-if="show_error">{{ errors[0] }}</p>
        </div>
        </ValidationProvider>
        <div class="form_label_wrapper">Password</div>
        <ValidationProvider
            name="password"
            rules="required"
            v-slot="{ errors }"
            slim
          >
        <div class="form_element_wrapper">
          <input
            type="password"
            v-model="user.password"
            placeholder="Enter password here"
            ref="password"
            :class="errors[0] && show_error ? 'warning-border':''"
            @keyup.enter="$refs.submit.click()"
          />
          <p class="error-class" v-if="show_error">{{ errors[0] }}</p>
        </div>
        </ValidationProvider>
        <div class="form_foorter_wrapper">
          <!-- <label>
            <input type="checkbox" />
            <span>Remember me</span>
          </label> -->
        </div>
        <div class="form_element_wrapper">
          <button @click="submit" ref="submit" :disabled="submit_btn.disabled">{{submit_btn.value}}</button>
        </div>
      </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  created(){
    localStorage.clear();
    if(window.checkIdleSession){
      clearInterval(window.checkIdleSession);
    }
    if(window.currentClientTime){
      clearInterval(window.currentClientTime);
    }
  },
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      submit_btn: {
        disabled: false,
        value: "SIGN IN"
      },
      show_error:false
    };
  },
  methods: {
    async submit() {
      this.show_error = false;
      let validator = await this.$refs.observer.validate();
      if(!validator){
        this.show_error = true;
      }else{
        this.submit_btn.disabled = true;
        this.submit_btn.value = "SIGNING IN...";
        this.axios
          .post("/api/auth/login", this.user)
          .then(response => {
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SIGN IN";
            localStorage.token = response.data.token;
            this.$router.push("/verifyotp");
          })
          .catch(error => {
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SIGN IN";
            console.log(error);
          });
      }
    }
  }
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
}
.login_page {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}
.left_part {
  width: 100%;
  height: 100%;
  background: #1b1b1b url(../assets/login-bg.jpg) no-repeat;
  background-size: contain;
  min-height: 200px;
  background-position: center;
}
.right_part {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.right_part .logo_div {
  width: 175px;
  height: 90px;
  margin-bottom: 30px;
}
.right_part .logo_div img {
  display: block;
  width: 100%;
  height: auto;
}
.right_part h2.sp {
  display: block;
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 20px;
}
.right_part .login_form_wrapper {
  width: 460px;
  height: auto;
  min-height: 100px;
  padding: 20px 0;
}
.form_label_wrapper {
  height: auto;
  font-size: 18px;
  padding: 10px 30px 0 30px;
  margin-bottom: 10px;
}
.form_element_wrapper {
  margin-bottom: 20px;
  height: 40px;
}
.form_foorter_wrapper {
  height: 30px;
  font-size: 18px;
  padding: 10px 30px 0 30px;
  margin-bottom: 20px;
}
label {
  display: block;
  float: left;
}
input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
}
a.forgot_password {
  display: block;
  float: right;
  font-size: 14px;
  margin-right: 0;
  color: #000;
}
input[type="text"] {
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  padding: 5px 30px;
}
input[type="password"] {
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  padding: 5px 30px;
}
button {
  width: 100%;
  height: 50px;
  background: #ffd10a;
  color: #000;
  font-size: 16px;
  padding: 5px 30px;
  border: 0;
}

button:disabled {
  background: #ffe25f;
}
</style>