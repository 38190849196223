<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPING</h4>
    <div class="step_view_wrapper">
      <div class="strip">
        <div class="strip_half"></div>
        <div class="strip_half"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two inactive">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three inactive">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>
    <section>
      <h2>CHOOSE ENTRY METHOD</h2>
      <div class="radio_wrapper1">
        <label>
          <input type="radio" name="entry_method" />
          <span>Single</span>
        </label>
        <label>
          <input type="radio" name="entry_method" />
          <span>Batch</span>
        </label>
      </div>
    </section>
    <section>
      <h2>SERVICE OPTIONS</h2>
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Requester Name
                  <span>*</span>
                </label>
                <input type="text" placeholder="Name" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Unit
                  <span>*</span>
                </label>

                <select>
                  <option>Select</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Date
                  <span>*</span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="date"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                ></flat-pickr>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Type of Goods
                  <span>*</span>
                </label>
                <select>
                  <option>Select</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Mode of Despatch
                  <span>*</span>
                </label>
                <select>
                  <option>Select</option>
                </select>
              </div>
            </td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="form_section1">
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo1.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo2.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo3.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo4.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo5.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo6.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo7.png" />
          <input type="radio" name="company" />
        </label>
        <label class="radio_with_img_labels">
          <img src="../../assets/a_logo8.png" />
          <input type="radio" name="company" />
        </label>
      </div>
    </section>

    <section>
      <h2>DELERY OPTIONS</h2>
      <div class="form_section1">
        <div class="special_panel">
          <header>Service Name</header>
          <div class="sp_body">
            <div class="a_part">
              <div class="label_and_element_wrapper sp">
                <label>
                  Choose a delery service
                  <span>*</span>
                </label>
                <select>
                  <option>Select</option>
                  <option>Domestic</option>
                  <option>International</option>
                </select>
              </div>
              <div class="service_logo">
                <img src="../../assets/a_logo1.png" />
              </div>
            </div>
            <div class="a_part">
              <div class="infos">Some infos that are invisible untle selecting courier</div>
              <a class="view_rate_card">
                View Rate Card
                <i class="material-icons">receipt</i>
              </a>
            </div>
          </div>
        </div>
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Customer reference number
                  <span>*</span>
                </label>
                <input type="text" placeholder="Enter" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  PTAED
                  <span>*</span>
                </label>
                <input type="text" placeholder="Enter" />
              </div>
            </td>
          </tr>
        </table>
        <label class="lone_chk_label">
          <input type="checkbox" />
          <span>Priority Importance</span>
        </label>
      </div>
    </section>
    <h4>ADDRESS DETAILS</h4>
    <section>
      <div class="address_details_wrapper">
        <header>
          <h2>PICKUP DETAILS</h2>
          <a class="yellow_bt" @click="showEditContact = true">
            Address Book
            <i class="material-icons">account_box</i>
          </a>
        </header>
        <div class="form_section1">
          <table>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Full Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Company Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Branch Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Address 1
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Address 2
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Suburub
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    City
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    State / Province
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Postal Code
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Country
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Phone
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Mobile
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Email
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="address_details_wrapper">
        <header>
          <h2>DELIVERY DETAILS</h2>

          <a class="yellow_bt" @click="showEditContact = true">
            Address Book
            <i class="material-icons">account_box</i>
          </a>
          <div class="selected_count">
            <i class="material-icons">account_circle</i>
            <span>0 Selected</span>
          </div>
        </header>
        <div class="form_section1">
          <table>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Full Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Company Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Branch Name
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Address 1
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Address 2
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Suburub
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    City
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    State / Province
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Postal Code
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Country
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Phone
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Mobile
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="label_and_element_wrapper">
                  <label>
                    Email
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter" />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>

    <section class="centered">
      <a class="link_bt bt_cancel">Previous</a>
      <a class="link_bt bt_cancel">Cancel</a>
      <router-link to="/pages/ServiceUpdate" class="link_bt bt_save">Save and Next</router-link>
    </section>

    <div class="black-overlay" v-show="showEditContact">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal v-show="showEditContact" @closeModal="showEditContact = false" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AddressBookModal from "./AddressBookModal.vue";
export default {
  name: "HelloWorld",
  components: {
    flatPickr,
    AddressBookModal
  },
  data() {
    return {
      configDate: {
        altInput: true,
        noCalendar: false
      },
      showEditContact: false
    };
  }
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #ffd10a;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #ffd10a;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.radio_wrapper1 {
  width: 300px;
  height: 100px;
  background: #fcfcfc;
  display: flex;
  padding: 20px 0;
}
.radio_wrapper1 label {
  width: auto;
  height: 30px;

  display: block;
  margin: 5px;
}
.radio_wrapper1 label input[type="radio"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.radio_wrapper1 label span {
  width: auto;
  height: 20px;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  top: -5px;
}

.radio_with_img_labels {
  width: 180px;
  height: 80px;
  background: #fff;
  color: #333;
  padding: 5px;
  margin: 10px 20px;
  display: inline-block;
}
.radio_with_img_labels img {
  display: block;
  margin: auto;
  height: 40px;
}
.radio_with_img_labels input[type="radio"] {
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
}

.special_panel {
  background: #fff;
  width: 100%;
}
.special_panel header {
  height: 40px;
  background: #333;
  color: #ccc;
  font-size: 16px;
  line-height: 40px;
  padding: 0 20px;
}
.special_panel .sp_body {
  background: #fff;
  padding: 20px;
}
.special_panel .sp_body .a_part {
  width: 50%;
  display: inline-block;
  position: relative;
  height: 100%;
}
.special_panel .sp_body .a_part .infos {
  padding: 20px 0;
  color: #333;
  font-size: 16px;
}
.special_panel .sp_body .a_part .view_rate_card {
  display: block;
  background: #ffd10a;
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: -125px;
}
.label_and_element_wrapper.sp {
  width: 70%;
}
.lone_chk_label {
  padding: 20px;
  display: block;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.address_details_wrapper {
  width: 45%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
}
.address_details_wrapper header {
  height: 60px;
}
.address_details_wrapper header h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: block;
  float: left;
}
.address_details_wrapper header .yellow_bt {
  font-size: 20px;
  padding: 0 10px;
  float: right;
  width: 200px;
  height: 40px;
  background: gold;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.address_details_wrapper header .yellow_bt i {
  font-size: 24px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);
  float: right;
  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  float: right;
  margin-right: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}
</style>