<template>
  <div class="mailroom_receipt_wrapper">
    <section class="consignment_header">
      <i class="closeme adj1" @click="closeModal">X</i>
      <a class="bt_print" @click="printPage">
        <i class="material-icons">print</i>
        Print
      </a>
    </section>
    <section class="mailroom_receipt">
      <div class="barcode_div">
        <barcode :value="data.booking_prefix + data.booking_id" tag="img"/>
      </div>
      <div class="details_row">
        <div>RECEIPIENT</div>
        <div>{{data.delivery_name}}</div>
      </div>
      <div class="details_row">
        <div>DELIVER TO</div>
        <div>{{data.delivery_address_1}} , {{data.delivery_suburb}} , {{data.delivery_postal_code}} , {{data.delivery_state}} , {{data.delivery_country}}</div>
      </div>
      <div class="details_row">
        <div>PHONE</div>
        <div>{{data.delivery_phone}}</div>
      </div>
      <div class="details_row">
        <div>PIECES</div>
        <div>{{data.item_qty}}</div>
      </div>
      <div class="details_row">
        <div>SEND BY</div>
        <div>{{data.requester_name}}</div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "MailroomReceipt",
  methods: {
    closeModal() {
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    printPage(){
      window.print();
    }
  },
  props:['data']
};
</script>
<style scoped>
.mailroom_receipt_wrapper {
  border: 10px solid #fff;
}
section.mailroom_receipt {
  width: 100%;
  height: 192px;
  padding: 0 20px;
}

.mailroom_receipt .barcode_div {
  width: 180px;
  height: auto;
  margin: 5px auto;
}
.mailroom_receipt .barcode_div img {
  width: 100%;
  height: auto;
  display: block;
}
.mailroom_receipt .barcode_div p {
  text-align: center;
  font-size: 13px;
  font-weight: 300;
}
.mailroom_receipt .details_row {
  display: flex;
  padding: 1px 0;
  font-size: 15px;
  text-align: left;
}
.mailroom_receipt .details_row div:first-child {
  width: 100px;
  font-weight: 700;
  flex-shrink: 0;
}
.barcode_div img{
  height:80px !important;
  width:100%;
}
</style>