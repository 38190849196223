var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp2"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('th',[_vm._v("Carrier Name")]),_c('th',[_vm._v("Service Name")]),_c('th',[_vm._v("Service Code")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Action")])]),_vm._l((_vm.services),function(service,i){return _c('tr',{key:i},[_c('td',[_c('ValidationProvider',{attrs:{"name":"carrier","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(service.provider_id),expression:"service.provider_id"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!service.is_edit_mode},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(service, "provider_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Carrier")]),_vm._l((_vm.carriers),function(carrier,i){return _c('option',{key:'carriers'+i,domProps:{"value":carrier.id}},[_vm._v(_vm._s(carrier.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(service.name),expression:"service.name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","maxlength":_vm.max,"disabled":!service.is_edit_mode},domProps:{"value":(service.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(service, "name", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(service.code),expression:"service.code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","maxlength":_vm.max,"disabled":!service.is_edit_mode},domProps:{"value":(service.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(service, "code", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"type","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(service.type),expression:"service.type"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!service.is_edit_mode},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(service, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Domestic")]),_c('option',{attrs:{"value":"I"}},[_vm._v("International")]),_c('option',{attrs:{"value":"M"}},[_vm._v("Messenger")])])])]}}],null,true)})],1),_c('td',{},[_c('a',{staticClass:"table_action_btn",attrs:{"title":"DELETE"},on:{"click":function($event){return _vm.deleteService(service.id)}}},[_vm._v("DELETE")]),(service.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"CANCEL"},on:{"click":function($event){return _vm.cancelService()}}},[_vm._v("CANCEL")]):_vm._e(),(service.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"UPDATE"},on:{"click":function($event){return _vm.updateService(service)}}},[_vm._v("UPDATE")]):_vm._e(),(!service.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"EDIT"},on:{"click":function($event){service.is_edit_mode=true}}},[_vm._v("EDIT")]):_vm._e()])])})],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }