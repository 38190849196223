import Vue from "vue";
import Router from "vue-router";
import Login from "./components/Login.vue";
import OtpVerify from "./components/OtpVerify.vue";
import Dashboard from "./components/pages/Dashboard.vue";
import MailRoom from "./components/pages/MailRoom.vue";
import Encoding from "./components/pages/Encoding.vue";
import AddressBook from "./components/pages/AddressBook.vue";
import Reports from "./components/pages/Reports.vue";
import ReportsMailroom from "./components/pages/ReportsMailroom.vue";
import ReportsDelivery from "./components/pages/ReportsDelivery.vue";
import ViewPending from "./components/pages/ViewPending.vue";
import TrackTrace from "./components/pages/TrackTrace.vue";
import ViewProcessed from "./components/pages/ViewProcessed.vue";
import ProfileSettings from "./components/pages/ProfileSettings.vue";
import PasswordManagement from "./components/pages/PasswordManagement.vue";
import TransmittalSlip from "./components/pages/TransmittalSlip.vue";
import GenerateSlip from "./components/pages/GenerateSlip.vue";
import ViewTransmittalSlip from "./components/pages/ViewTransmittalSlip.vue";
import ViewTransmittalSlipBranch from "./components/pages/ViewTransmittalSlipBranch.vue";
import CreateTransmittalSlipHubAdmin from "./components/pages/CreateTransmittalSlipHubAdmin.vue";
import CreateTransmittalSlipHubAdminByScan from "./components/pages/CreateTransmittalSlipHubAdminByScan.vue";
import CreateDispatchDeliveryNote from "./components/pages/CreateDispatchDeliveryNote.vue";
import ServiceDetails from "./components/pages/ServiceDetails.vue";
import ServiceUpdate from "./components/pages/ServiceUpdate.vue";
import ServiceConfirm from "./components/pages/ServiceConfirm.vue";
import BulkBooking from "./components/pages/BulkBooking.vue";
import BulkBookingUser from "./components/pages/BulkBookingUser.vue";
import TransportStatusBooking from "./components/pages/TransportStatusBooking.vue";
import UserManagement from "./components/pages/UserManagement.vue";

import ModeOfDispatch from "./components/pages/masterdata/ModeOfDispatch.vue";
import TypeOfGood from "./components/pages/masterdata/TypeOfGood.vue";
import Carriers from "./components/pages/masterdata/Carriers.vue";
import CarrierServices from "./components/pages/masterdata/CarrierServices.vue";
import Unit from "./components/pages/masterdata/Unit.vue";
import Division from "./components/pages/masterdata/Division.vue";
import Hub from "./components/pages/masterdata/Hub.vue";
import Ptaeo from "./components/pages/masterdata/Ptaeo.vue";
import CostCenter from "./components/pages/masterdata/CostCenter.vue";
import DepartmentLocations from "./components/pages/masterdata/DepartmentLocations.vue";
import MailroomLocations from "./components/pages/masterdata/MailroomLocations.vue";
import EmailTemplate from "./components/pages/masterdata/EmailTemplate.vue";
import Sidebar from "./components/Sidebar.vue";
import Help from "./components/pages/Help.vue";
import Support from "./components/pages/Support.vue";

Vue.use(Router);

const router =  new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path:"/verifyotp",
      name:"verifyotp",
      component: OtpVerify
    },
    {
      path: "/pages",
      name: "sidebar",
      component: Sidebar,
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          meta:{
            roles: ["user", "hub_user", "hub_admin", "customer_service_user", "super_admin"]
          }
        },
        {
          path: "Encoding/:id?",
          name: "Encoding",
          component: Encoding,
          meta: {
            roles: ["user", "hub_user", "hub_admin"]
          }
        },
        {
          path: "MailRoom/:id?",
          name: "MailRoom",
          component: MailRoom,
          meta: {
            roles: ["hub_user", "hub_admin"]
          }
        },
        {
          path: "AddressBook",
          name: "AddressBook",
          component: AddressBook,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "Reports",
          name: "Reports",
          component: Reports,
          meta: {
            roles: ["hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "ReportsMailroom",
          name: "ReportsMailroom",
          component: ReportsMailroom,
          meta: {
            roles: [ "hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "ReportsDelivery",
          name: "ReportsDelivery",
          component: ReportsDelivery,
          meta: {
            roles: ["hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "ViewPending",
          name: "ViewPending",
          component: ViewPending,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "TrackTrace",
          name: "TrackTrace",
          component: TrackTrace,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "customer_service_user", "super_admin"]
          }
        },
        {
          path: "ViewProcessed",
          name: "ViewProcessed",
          component: ViewProcessed,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "super_admin"]
          }
        },
        {
          path: "ProfileSettings",
          name: "ProfileSettings",
          component: ProfileSettings,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "customer_service_user", "super_admin"]
          }
        },
        {
          path: "PasswordManagement",
          name: "PasswordManagement",
          component: PasswordManagement,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "TransmittalSlip",
          name: "TransmittalSlip",
          component: TransmittalSlip
        },
        {
          path: "GenerateSlip",
          name: "GenerateSlip",
          component: GenerateSlip,
          meta: {
            roles: ["user", "hub_user", "hub_admin"]
          }
        },
        {
          path: "ViewTransmittalSlip",
          name: "ViewTransmittalSlip",
          component: ViewTransmittalSlip,
          meta: {
            roles: ["hub_user", "hub_admin"]
          }
        },
        {
          path: "ViewTransmittalSlipBranch",
          name: "ViewTransmittalSlipBranch",
          component: ViewTransmittalSlipBranch,
          meta: {
            roles: ["user", "hub_user", "hub_admin"]
          }
        },
        {
          path: "CreateTransmittalSlipHubAdmin",
          name: "CreateTransmittalSlipHubAdmin",
          component: CreateTransmittalSlipHubAdmin,
          meta:{
            roles: ["hub_user", "hub_admin"]
          }
        },
        {
          path: "CreateTransmittalSlipHubAdminByScan",
          name: "CreateTransmittalSlipHubAdminByScan",
          component: CreateTransmittalSlipHubAdminByScan,
          meta: {
            roles: [ "hub_user", "hub_admin"]
          }
        },
        {
          path: "CreateDispatchDeliveryNote/:type?",
          name: "CreateDispatchDeliveryNote",
          component: CreateDispatchDeliveryNote,
          meta: {
            roles: ["user", "hub_user", "hub_admin"]
          }
        },
        {
          path: "ServiceDetails",
          name: "ServiceDetails",
          component: ServiceDetails
        },
        {
          path: "ServiceUpdate",
          name: "ServiceUpdate",
          component: ServiceUpdate
        },
        {
          path: "ServiceConfirm",
          name: "ServiceConfirm",
          component: ServiceConfirm
        },
        {
          path: "BulkBooking",
          name: "BulkBooking",
          component: BulkBooking,
          meta: {
            roles: ["hub_admin", "super_admin"]
          }
        },
        {
          path: "BulkBookingUser",
          name: "BulkBookingUser",
          component: BulkBookingUser,
          meta: {
            roles: ["user"]
          }
        },
        {
          path: "TransportStatusBooking",
          name: "TransportStatusBooking",
          component: TransportStatusBooking,
          meta: {
            roles: ["hub_admin", "super_admin"]
          }
        },
        {
          path: "UserManagement",
          name: "UserManagement",
          component: UserManagement,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Help",
          name: "Help",
          component: Help,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "customer_service_user", "super_admin"]
          }
        },
        {
          path: "Support",
          name: "Support",
          component: Support,
          meta: {
            roles: ["user", "hub_user", "hub_admin", "customer_service_user", "super_admin"]
          }
        },
        {
          path: "ModeOfDispatch",
          name: "ModeOfDispatch",
          component: ModeOfDispatch,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "TypeOfGood",
          name: "TypeOfGood",
          component: TypeOfGood,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Carriers",
          name: "Carriers",
          component: Carriers,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "CarrierServices",
          name: "CarrierServices",
          component: CarrierServices,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Unit",
          name: "Unit",
          component: Unit,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Division",
          name: "Division",
          component: Division,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Hub",
          name: "Hub",
          component: Hub,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "Ptaeo",
          name: "Ptaeo",
          component: Ptaeo,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "CostCenter",
          name: "CostCenter",
          component: CostCenter,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "DepartmentLocations",
          name: "DepartmentLocations",
          component: DepartmentLocations,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "MailroomLocations",
          name: "MailroomLocations",
          component: MailroomLocations,
          meta: {
            roles: ["super_admin"]
          }
        },
        {
          path: "EmailTemplate",
          name: "EmailTemplate",
          component: EmailTemplate,
          meta: {
            roles: ["super_admin"]
          }
        }
      ]
    }
  ]
});

import Toast from "./mixins/toast.js";
import Auth from "./mixins/auth.js";
import http from './http.js';
router.beforeEach((to, from, next) => {
  if(to.fullPath.includes("pages")){
    try {
      if (!to.meta.roles.includes(Auth().user_type)) {
        http.post("api/auth/logout");
        throw ("Unauthorized access");
      }
    } catch (e) {
      localStorage.clear();
      Toast.error("You must login first");
      router.push("/");
    }
  }
  next();
});

export default router;