<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Carrier Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="name" :maxlength="max2"/>
                  </div>
                  </ValidationProvider>
                </td>
               <td rowspan="4" colspan="2" class="rowspanned_4_chk">
                  <ValidationProvider name="dispatch" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Mode of Dispatch
                      <span>*</span>
                    </label>
                    <div class="chk_group" >
                    <label v-for="(dispatch,i) in dispatches" :key="'dispatch'+i" :class="errors[0] ? 'warning-border':''">
                      <input type="checkbox" v-model="mode_of_dispatch" :value="dispatch.id"/>
                      <span>{{dispatch.name}}</span>
                    </label>
                  </div>
                   </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Carrier Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Carrier code Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="carrier_code" :maxlength="max2"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="description" rules="required|max:70" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Description
                      <span>*</span>
                    </label>
                    <textarea placeholder="Type Here Character Limit 70"  :class="errors[0] ? 'warning-border':''" v-model="description" :maxlength="max"></textarea>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Upload Logo
                      <span>*</span>
                    </label>
                    <label class="user_profile_upload ">
                      <span>Choose a File</span>
                      <input type="file"  ref="logo" @change="uploadFile" accept="image/*" :class="errors[0] ? 'warning-border':''"/>

                          <span v-if= "logo != '' ">
                        {{logoname}}
                      </span>
                    </label>
                  </div>
                  </ValidationProvider>
               
                  <ValidationProvider name="rate" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Upload Rate Card
                      <span>*</span>
                    </label>
                    <label class="user_profile_upload">
                      <span>Choose a File</span>
                      <input type="file"  ref="rate_card" @change="uploadFile" accept="image/*" :class="errors[0] ? 'warning-border':''"/>
                      <div v-if= "rate_card != '' ">
                        {{ratecardname}}
                      </div>
                    </label>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Carriers",
  data(){
    return {
      max:70,
      max2:30,
      dispatches:[],
      name : "",
      mode_of_dispatch: [],
      carrier_code:"",
      logo:"",
      description:"",
      rate_card:"",
      logoname:"",
      ratecardname:""
    }
  },
  created() {
    this.fetchModeOfDispatch();
  },
  methods: {
    fetchModeOfDispatch(){
      this.axios.get("/api/dispatch/")
      .then(response => {
          this.dispatches = response.data.mode_of_dispatch;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    uploadFile () {
      this.logo = this.$refs["logo"].files[0];
      this.logoname = this.logo.name;
      this.rate_card = this.$refs["rate_card"].files[0];
      this.ratecardname = this.rate_card.name;
    },
    submit(){
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("carrier_code", this.carrier_code);
      formData.append("logo", this.logo);
      formData.append("rate_card", this.rate_card);
      formData.append("description", this.description);

      this.mode_of_dispatch.forEach(element =>{
        formData.append("mode_of_dispatch[]", element);
      });
      this.$refs.observer.validate();
        this.axios
        .post("/api/provider/", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style>
.rowspanned_4_chk {
  width: 40%;
  overflow-x: scroll;
}
.rowspanned_4_chk .chk_group {
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  height: 320px;
  overflow-y: scroll;
}
.rowspanned_4_chk .chk_group label {
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background: #efefef;
}
.rowspanned_4_chk .chk_group label:last-child {
  margin-bottom: 0;
}
.rowspanned_4_chk .chk_group label span {
  display: inline-block;
  color: #333;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  position: relative;
  top: -3px;
}
.rowspanned_4_chk .chk_group label input[type="checkbox"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  position: relative;
  top: 2px;
}
</style>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  margin-bottom:30px;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
</style>