<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPING</h4>
    <div class="step_view_wrapper">
      <div class="strip">
        <div class="strip_half" style="display:inline-block"></div>
        <div class="strip_half" style="display:inline-block"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>
    <section class="two_blocks_row">
      <div class="editable_block">
        <header>
          <h3>SENDER DETAILS</h3>
          <a class="edit_details">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>Isabelita M. David</p>
        </div>
      </div>

      <div class="editable_block">
        <header>
          <h3>SHIPMENT DETAILS</h3>
          <a class="edit_details">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>Isabelita M. David</p>
        </div>
      </div>
    </section>

    <section class="two_blocks_row">
      <div class="editable_block">
        <header>
          <h3>PICKUP DETAILS</h3>
          <a class="edit_details">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>Isabelita M. David</p>
          <p>REG</p>
          <p>The World Health Organization</p>
          <p>Regional Office for the Western Pacific (WPRO),P.O Box 2932, Manila</p>
        </div>
      </div>

      <div class="editable_block">
        <header>
          <h3>DELIVERY DETAILS</h3>
          <div class="selected_count">
            <i class="material-icons">account_circle</i>
            <span>1 Selected</span>
          </div>
          <a class="edit_details">
            <i class="material-icons">create</i>Edit
          </a>
        </header>
        <div class="block_contents">
          <p>Dr C. Ijsselmuiden</p>
          <p>WHO</p>
          <p>Interim Executive Director, Global Forum for Health Research</p>
          <p>1-5 route des Morillons, , PO Box 2100</p>
        </div>
      </div>
    </section>
    <section class="two_blocks_row">
      <div class="editable_block full">
        <header>
          <h3>NOTIFICATION</h3>
        </header>
        <div class="block_contents">
          <p>Email upon shipping</p>
          <div class="form_section1">
            <table class="conf_adj_1">
              <tr>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" /> Shipper
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <input type="text" placeholder="Name" />
                  </div>
                </td>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" /> Receiver
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <input type="text" placeholder="Name" />
                  </div>
                </td>
              </tr>

              <tr>
                <td class="series_td">
                  <div class="label_and_element_wrapper">
                    <label class="chk_label_conf">
                      <input type="checkbox" />
                      Pro Active Delivery Notofication
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper"></div>
                </td>
                <td class="series_td">
                  <div class="label_and_element_wrapper"></div>
                </td>
                <td>
                  <div class="label_and_element_wrapper"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section>
      <label class="lone_chk_label">
        <input type="checkbox" />
        <span>I have read and agree to the terms and conditions</span>
      </label>
      <a class="tandc_link">Terms and Conditions</a>
    </section>
    <section class="centered">
      <router-link to="/pages/ServiceUpdate" class="link_bt bt_cancel">Previous</router-link>
      <a class="link_bt bt_cancel">Cancel</a>
      <a class="link_bt bt_draft">Draft</a>
      <a class="link_bt bt_save">Submit</a>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelloWorld"
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #ffd10a;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #ffd10a;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.label_and_element_wrapper.sp {
  width: 33%;
}
.lone_chk_label {
  padding: 20px;
  display: inline-block;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.service_details_container > .two_blocks_row {
  display: flex;
  height: auto;
  padding: 40px 40px 0 40px;
}
.two_blocks_row > .editable_block {
  width: 50%;
  height: auto;
  padding: 0;
  padding-right: 30px;
}
.two_blocks_row > .editable_block:last-child {
  width: 50%;
  height: auto;
  padding: 0;
  padding-left: 30px;
}
.two_blocks_row > .editable_block.full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.two_blocks_row header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.two_blocks_row header h3 {
  font-weight: 600;
  display: block;
  line-height: 40px;
  padding-bottom: 10px;
  border-bottom: 4px solid #666;
}
.two_blocks_row header .edit_details {
  display: inline-block;
  text-align: center;
  background: #ffd10a;
  color: #fff;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin: auto 0 0 auto;
}
.two_blocks_row header .edit_details i {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-left: 15px;
}
.two_blocks_row .block_contents {
  background: #ececec;
  border: 1px solid #ddd;
  height: 300px;
  padding: 20px;
}
.two_blocks_row .block_contents p {
  padding-bottom: 5px;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);

  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;

  margin-left: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}
.lone_chk_label {
  padding: 0;
  display: inline-block;
  margin-left: 40px;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.chk_label_conf {
  padding: 0;
  display: inline-block;
}
.chk_label_conf input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
}
.tandc_link {
  color: #f00;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: -5px;
}
.bt_draft {
  background: #999;
  color: #000;
}
table.conf_adj_1 td:first-child {
  width: 300px;
}
</style>