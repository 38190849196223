<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub/Mailroom Location Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Hub/Mailroom Location Name Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="division.name" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="Code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Hub/Mailroom Location Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Hub/Mailroom Location Code Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="division.division_code" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              
              <tr>
                <td>
                  <ValidationProvider name="address 1" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address line 1
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Address line 1 Character Limit 60" :class="errors[0] ? 'warning-border':''" v-model="division.address_1" :maxlength="max1"/>
                    </div>
                  </ValidationProvider>
                </td>
                <td colspan="2">
                  <ValidationProvider name="address 2" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address line 2
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Address line 2 Character Limit 60" :class="errors[0] ? 'warning-border':''" v-model="division.address_2" :maxlength="max1"/>
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="postal code" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Postal Code
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Postal Code Character Limit 10" v-model="division.postal_code" :maxlength="max2" :class="errors[0] ? 'warning-border':''"/>
                    </div>
                  </ValidationProvider>
                </td>
                <td colspan="2">
                  <ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        City
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="City Character Limit 30" :class="errors[0] ? 'warning-border':''" v-model="division.city" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                      <span></span>
                    </label>
                    <input type="text" placeholder="Suburb Character Limit 30" v-model="division.suburb" :maxlength="max"/>
                  </div>
                </td>
                <td colspan="2">
                  <ValidationProvider name="country" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Country
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Country  Character Limit 30" :class="errors[0] ? 'warning-border':''" v-model="division.country" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        State/Province
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="State/Province Character Limit 30" :class="errors[0] ? 'warning-border':''" v-model="division.state" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                </td>
                <td colspan="2">
                  <!-- <ValidationProvider name="email" rules="required|max:30" v-slot="{ errors }" slim> -->
                    <div class="label_and_element_wrapper">
                      <label>
                        Email
                        <span></span>
                      </label>
                      <input type="text" placeholder="Email Character Limit 60" v-model="division.email" :maxlength="max1"/>
                    </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="phone" rules="required|max:30" v-slot="{ errors }" slim> -->
                    <div class="label_and_element_wrapper">
                      <label>
                        Phone
                        <!-- <span>*</span> -->
                      </label>
                      <input type="text" placeholder="Phone Character Limit 20" v-model="division.phone" :maxlength="max3"/>
                    </div>
                  <!-- </ValidationProvider> -->
                </td>
                  <td>
                  <!-- <ValidationProvider name="token" rules="required|max:100" v-slot="{ errors }" slim> -->
                    <div class="label_and_element_wrapper">
                      <label>
                        Token
                        <!-- <span>*</span> -->
                      </label>
                      <textarea  placeholder="Token Character Limit 100"  v-model="division.token" :maxlength="max4"></textarea>
                    </div>
                  <!-- </ValidationProvider> -->
                </td>
               
              </tr>
              <tr>
                <td>
                  <ValidationProvider name=" Enable Email Notification " rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Enable Email Notification ? 
                      <span></span>
                    </label>
                    <input type="checkbox" v-model="division.email_notification" true-value="1" false-value="0" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                </td>
              </tr>
            </table>
            <div class="sub_form_section" v-for="(location,i) in division.location" :key="'location_container_'+i">
              <div class="add_remove_btns">
                <a class="add_bt" @click="addLocationFields"></a>
                <a class="remove_bt" @click="removeLocationFields(i)" v-if="division.location.length > 1"
                ></a>
              </div>
              <table class="sub_table">
                <tr>
                  <td>
                  <ValidationProvider name="Inbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Inbound Location
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Inbound Location Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="Inbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Inbound Location Reference
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Inbound Location Reference Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location_ref" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                  <ValidationProvider name="Outbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Outbound Location
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Outbound Location Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="Outbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Outbound Location Reference
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Outbound Location Reference Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location_ref" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                  <ValidationProvider name="Email Template" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Email Template
                        <span>*</span>
                      </label>
                      <select v-model="location.default_mailroom_email_template_id" :class="errors[0] ? 'warning-border':''">
                        <option value disabled>Select</option>
                        <option
                          v-for="(email_template,i) in email_templates"
                          :key="'emailtemplates'+i"
                          :value="email_template.id"
                        >{{email_template.title}}</option>
                      </select>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="Is Default" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Is Default
                        <span></span>
                      </label>
                      <input type="checkbox" v-model="location.is_default" true-value="1" false-value="0" @click="setDefault(i)" :class="errors[0] ? 'warning-border':''"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
              </table>
            </div>
            
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="addMailroomLocation">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MailroomLocations",
  created(){
    this.fetchUser();
  },
  data() {
    return {
      max:30,
      max1:60,
      max2:10,
      max3:20,
      division: {
        name: "",
        division_code: "",
        email_notification:1,
        address_1:"",
        address_2:"",
        postal_code:"",
        country:"",
        city:"",
        suburb:"",
        state:"",
        phone:"",
        email:"",
        token:"",
        location: [
          {
            inbound_location: "",
            inbound_location_ref: "",
            outbound_location: "",
            outbound_location_ref: "",
            default_mailroom_email_template_id: "",
            is_default: 1
          }
        ]
      },
      email_templates: [],
      user:{}
    };
  },
  methods: {
    addMailroomLocation() {
      console.log(this.division);
      this.$refs.observer.validate();
      this.axios
        .post("api/divisionslocation", this.division)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit("redirect");
        })
        .catch(error => {
          console.log(error);
        });
    },
    addLocationFields() {
      this.division.location.push({
        inbound_location: "",
        inbound_location_ref: "",
        outbound_location: "",
        outbound_location_ref: "",
        default_mailroom_email_template_id: "",
        is_default: 0
      });
    },
    removeLocationFields(i) {
      this.division.location.splice(i, 1);
    },
    setDefault(i){
      this.division.location.forEach(element => {
        element.is_default = 0;
      });
      this.division.location[i].is_default = 1;
    },
    fetchUser(){
      this.axios.get("api/user")
      .then(response => {
        this.user = response.data.user;
        this.fetchEmailTemplate();
      });
    },
    fetchEmailTemplate() {
      this.axios
        .get("/api/emailtemplate/")
        .then(response => {
          this.email_templates = response.data.email_template;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
.default_checkbox{    
  width:22px;   
  height:22px   
}
</style>