<template>
  <div class="courier_settings_tab">
    <section>
      <h3>Courier Settings</h3>
    </section>
    <div class="encoding_form_wrapper">
      <div class="sub_sections">
        <div class="form_section1">
          <section>
            <table>
              <tr>
                <td colspan="4">
                  <div class="label_and_element_wrapper halved">
                    <label>
                      Type Of Goods
                      <span>*</span>
                    </label>
                    <select v-model="usergood.goods_id">
                      <option value="" disabled>Select package type</option>
                      <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.id">{{good.type}}</option>
                    </select>
                  </div>
                </td>
              </tr>
             <!-- <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Length
                      <span class="dim_text">(Optional)</span>
                    </label>

                    <input type="text" placeholder="Name" value="0"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Width
                      <span class="dim_text">(Optional)</span>
                    </label>

                    <input type="text" placeholder="Name" value="0"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Height
                      <span class="dim_text">(Optional)</span>
                    </label>

                    <input type="text" placeholder="Name" value="0"/>
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Weight
                      <span class="dim_text">(Optional)</span>
                    </label>
                    <input type="text" placeholder="Name" value="0"/>
                  </div>
                </td>
              </tr>-->
              <tr>
                <td colspan="4">
                  <div class="label_and_element_wrapper halved">
                    <label>Your Monthly Budget
                    <span>*</span>
                    </label>
                     
                    <input type="text" placeholder="Type" v-model="usergood.monthly_budget" />
                  </div>
                </td>
              </tr>
            </table>
          </section>
        </div>
      </div>
    </div>
    <section class="centered">

      <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
      <a class="link_bt bt_save" @click="goodsUpdate">Save</a>
    </section>
  </div>
</template>
<script>
  export default {
  name: "ProfileSettings",
  data() {
    return {
      usergood:{
        goods_id:"",
        monthly_budget:"0"
      },
      goods:[]
    };
  },
  created(){
    this.fetchGood();
    this.fetchusergood();
  },
  methods:{
    fetchusergood(){
      this.axios.get("/api/user/data")
      .then(response => {
        this.usergood.goods_id = response.data.msg.goods_id ? response.data.msg.goods_id : "";
        this.usergood.monthly_budget = response.data.msg.monthly_budget ? response.data.msg.monthly_budget : 0;
      })
      .catch(error =>{
        console.log(error)
      });
    },
    fetchGood(){
      this.axios.get("/api/goods/")
      .then(response => {
        this.goods = response.data.goods;
      })
      .catch(error => {
        console.log(error);
      });
    },
    goodsUpdate() {
        this.axios.post('/api/user/goods', this.usergood)
        .then(response => {
          this.toast.success(response.data.msg);
        })
        .catch(error => {
          console.log(error);
        });
    },
    cancelGoods(){
      this.fetchusergood();
    }
  }
};
</script>
<style scoped>
.encoding_form_wrapper .sub_sections:last-child {
  padding: 0;
}
.courier_settings_tab h3 {
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0 10px;
}
.label_and_element_wrapper.halved {
  width: 50% !important;
}
.dim_text {
  color: #999;
}
</style>