<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <table>
        <tr>
          <th>Type</th>
          <th>Mode of Dispatch</th>
          <th class="width_40px">Weight</th>
          <th class="width_40px">Length</th>
          <th class="width_40px">Width</th>
          <th class="width_40px">Height</th>
          <th class="width_40px">
            M
            <sup>3</sup>
          </th>
          <th class="width_40px">Cubic/Kg</th>
          <th class="width_40px">Danger Good</th>
          <th class="width_40px">Food</th>
          <th class="width_40px">Volume</th>
          <th>Action</th>
        </tr>
        <tr v-for="(good,i) in goods" :key="i">
          <td>
            <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.type"
                :disabled="!good.is_edit_mode" :class="errors[0] ? 'warning-border':''"
              />
            </div>
            </ValidationProvider>
          </td>
          <td>
            <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
              <select v-model="good.dispatch_mode" :disabled="!good.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                <option value>Select Mode of Dispatch</option>
                <option v-for="(mode,i) in modes" :key="'mode'+i" :value="mode.id">{{mode.name}}</option>
              </select>
            </div>
            </ValidationProvider>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.weight"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.length"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.width"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.height"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.m3"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter Character limit 30" :maxlength="max"
                v-model="good.cubic_kg"
                :disabled="!good.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="checkbox" class="chk_prefered" :checked="good.is_dg" disabled />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="checkbox"
                class="chk_prefered"
                :id="good.id"
                :checked="good.is_food"
                disabled
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="checkbox"
                class="chk_prefered"
                :id="good.id"
                :checked="good.volume == 'N'"
                disabled
              />
            </div>
          </td>
          <td class>
            <a class="table_action_btn" title="DELETE" @click="deleteGoods(good.id)">DELETE</a>
            <a
              class="table_action_btn"
              title="CANCEL"
              v-if="good.is_edit_mode"
              @click="cancelGoods()"
            >CANCEL</a>
            <a
              class="table_action_btn"
              title="UPDATE"
              v-if="good.is_edit_mode"
              @click="updateGoods(good)"
            >UPDATE</a>
            <a
              class="table_action_btn"
              title="EDIT"
              v-if="!good.is_edit_mode"
              @click="good.is_edit_mode = true"
            >EDIT</a>
          </td>
        </tr>
      </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "TypeOfGood",
  components: {},
  data() {
    return {
      max:30,
      makeEditable: false,
      cancelEditable: true,
      goods: [],
      modes: []
    };
  },
  created() {
    this.fetchModeOfDispatch();
    this.fetchTypeOfGoods();
  },
  methods: {
    fetchTypeOfGoods() {
      this.axios
        .get("/api/goods/")
        .then(response => {
          console.log(response);
          this.goods = response.data.goods.map(element => {
            element.is_edit_mode = false;
            if (element.modes.length == 0) {
              element.dispatch_mode = "";
            } else {
              element.dispatch_mode = element.modes[0].id;
            }
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchModeOfDispatch() {
      this.axios
        .get("/api/dispatch/")
        .then(response => {
          console.log(response);
          this.modes = response.data.mode_of_dispatch;
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateGoods(data) {
      data.mode_of_dispatch = [data.dispatch_mode];
       this.$refs.observer.validate();
      this.axios
        .put("/api/goods/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteGoods(id) {
      if (confirm("Are you sure you want to delete this type of goods?")) {
        this.axios
          .delete("/api/goods/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchTypeOfGoods();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelGoods(){
      this.fetchTypeOfGoods();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1500px;
    padding-right: 20px;
  }
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
</style>