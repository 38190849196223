<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Hub/Mailroom Location Name</th>
          <th>Hub/Mailroom Location Code</th>
          <!-- <th>Inbound Location</th>
          <th>Inbound Location Reference</th>
          <th>Outbound Location</th>
          <th>Outbound Location Reference</th>
          <th>Email Template</th>
          <th>Default</th>
          <th>Email Notification</th> -->
          <th>Action</th>
        </tr>
        <tr v-for="(division,i) in divisions" :key="'division_'+i">
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Name Character Limit 30" :disabled="!division.is_edit_mode" v-model="division.name" :maxlength="max"/>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Code Character Limit 30" :disabled="!division.is_edit_mode" v-model="division.division_code" :maxlength="max"/>
            </div>
          </td>
         <!--  <td colspan="6" class="not_padded">
            <div class="inner_rows" v-for="(division_location,j) in division.division_locations" :key="'division_location_'+j">
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="!division.is_edit_mode" v-model="division_location.inbound_location"/>
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="!division.is_edit_mode" v-model="division_location.inbound_location_ref" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder=" Location" :disabled="!division.is_edit_mode" v-model="division_location.outbound_location" />
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Location Reference" :disabled="!division.is_edit_mode" v-model="division_location.outbound_location_ref"/>
                </div>
              </div>
              <div class="inner_row_cells">
                <div class="label_and_element_wrapper">
                  <select v-model="division_location.default_mailroom_email_template_id" :disabled="!division.is_edit_mode">
                    <option value disabled>Select</option>
                    <option
                      v-for="(email_template,k) in email_templates"
                      :key="'emailtemplates'+k"
                      :value="email_template.id"
                    >{{email_template.title}}</option>
                  </select>
                </div>
              </div>
              <div class="inner_row_cells" style="height:55px;">
                <div class="label_and_element_wrapper">
                    <input type="checkbox" class="checkbox" v-model="division_location.is_default" true-value="1" false-value="0" :disabled="!division.is_edit_mode" @click="setDefault(i,j)">
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="checkbox" class="checkbox" v-model="division.email_notification" true-value="1" false-value="0" :disabled="!division.is_edit_mode"/>
            </div>
          </td> -->
          <td class>
            <a class="edit_icon" title="EDIT" @click="location_id = division.id,is_editable=true"></a>
              <a v-if="division.branches.length != 0 " class="delete_icon" title="This Hub can't be deleted as it is linked with a Branch"></a>
              <a v-if="division.branches.length == 0 " class="delete_icon" title="DELETE" @click="deleteDivision(division.id)"></a>
            <a class="view_icon" title="VIEW" @click="location_id = division.id, is_editable=false"></a>
          </td>
        </tr>
      </table>
    </div>

    <div class="black-overlay" v-if="location_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <MailroomLocationPopup v-if="location_id" :location_id="location_id" @closeModal="fetchDivisions"  :is_editable="is_editable"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import MailroomLocationPopup from "./MailroomLocation_EditPopup.vue";

export default {
  name: "MailroomLocations",
  components: {
    MailroomLocationPopup
  },
  created(){
    this.fetchDivisions();
    // this.fetchUser();
  },
  data() {
    return {
      max:30,
      divisions:[],
      email_templates:[],
      user:{},
      location_id : ""
    };
  },
  methods:{
    fetchDivisions(){
      this.location_id = '';
      this.axios.get("api/division/")
      .then(response => {
        console.log(response);
        this.divisions = response.data.divisions.map(element => {
          element.is_edit_mode = false;
          return element;
        });
      })
      .catch(error => {
        console.log(error);
      });
    },
    /*fetchUser(){
      this.axios.get("api/user")
      .then(response => {
        this.user = response.data.user;
        this.fetchEmailTemplate();
      });
    },
    fetchEmailTemplate() {
      this.axios
        .get("/api/emailtemplate/")
        .then(response => {
          console.log(response);
          this.email_templates = response.data.division.filter(element => element.division_id == this.user.division_id);
        })
        .catch(error => {
          console.log(error);
        });
    },*/
    deleteDivision(id) {
      if (confirm('Are you sure you want to delete this division?')) {
          this.axios.delete("/api/division/" + id)
          .then(response => {
              this.toast.success(response.data.msg);
              this.fetchDivisions();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelDivision() {
      this.fetchDivisions();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1400px;
    padding-right: 20px;
  }
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 12.2%;
}
.jobs_table.sp2 > table tr th:last-child,
.jobs_table.sp2 > table tr td:last-child {
  min-width: 260px;
}
.jobs_table.sp2 > table tr td.not_padded {
  padding: 0;
}
.inner_rows {
  width: 100%;
}
.inner_row_cells {
  width: 16.66%;
  float: left;
  border-right: 1px solid #ccc;
  padding: 5px 2px 0 2px;
}
.inner_rows:last-child .inner_row_cells {
  padding-bottom: 5px;
}
.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
.checkbox{
  width:18px;
  height:18px;
}
.edit_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_edit.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.view_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_view.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
        .icons_wrapper {
          a {
              .grey {
                color: grey;
              }
          }
        }
</style>