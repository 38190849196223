var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('section',[_c('div',{staticClass:"form_section1"},[_c('div',{staticClass:"user_type"},[_c('label',{staticClass:"chk_label"},[_c('span',[_vm._v("User")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_type),expression:"user_type"}],staticClass:"chk_prefered",attrs:{"name":"usertype","type":"radio","value":"0","id":"hub-admin"},domProps:{"checked":_vm._q(_vm.user_type,"0")},on:{"change":function($event){_vm.user_type="0"}}})]),_c('label',{staticClass:"chk_label"},[_c('span',[_vm._v("Hub Admin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_type),expression:"user_type"}],staticClass:"chk_prefered",attrs:{"name":"usertype","type":"radio","value":"1","id":"user"},domProps:{"checked":_vm._q(_vm.user_type,"1")},on:{"change":function($event){_vm.user_type="1"}}})]),_c('label',{staticClass:"chk_label"},[_c('span',[_vm._v("Customer Service User")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_type),expression:"user_type"}],staticClass:"chk_prefered",attrs:{"name":"usertype","type":"radio","value":"2","id":"user"},domProps:{"checked":_vm._q(_vm.user_type,"2")},on:{"change":function($event){_vm.user_type="2"}}})]),_c('label',{staticClass:"chk_label"},[_c('span',[_vm._v("Super Admin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_type),expression:"user_type"}],staticClass:"chk_prefered",attrs:{"name":"usertype","type":"radio","value":"3","id":"user"},domProps:{"checked":_vm._q(_vm.user_type,"3")},on:{"change":function($event){_vm.user_type="3"}}})])]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"full name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Name\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Name Character Limit 30","maxlength":_vm.max2},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Email\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Email Character Limit 60","maxlength":_vm.max},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|max:60","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Password\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"Password","placeholder":"Password Character Limit 60","maxlength":_vm.max},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"branch","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Branch Name\n                    "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.branch_id),expression:"user.branch_id"}],class:errors[0] ? 'warning-border':'',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "branch_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.fetchBranch]}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Branch")]),_vm._l((_vm.branches),function(branch,i){return _c('option',{key:'branch'+i,domProps:{"value":branch.id}},[_vm._v(_vm._s(branch.name))])})],2)])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"cost","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Cost Center\n                    "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.cost_center_id),expression:"user.cost_center_id"}],class:errors[0] ? 'warning-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "cost_center_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Cost center")]),_vm._l((_vm.cost_centers),function(data,i){return _c('option',{key:'cost_center'+i,domProps:{"value":data.id}},[_vm._v(_vm._s(data.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Department\n                  ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.department_id),expression:"user.department_id"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "department_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Department")]),_vm._l((_vm.departments),function(department,i){return _c('option',{key:'delivery_department_code'+i,domProps:{"value":department.department_name}},[_vm._v(_vm._s(department.department_name))])})],2)])])]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"address1","rules":"required|max:60","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Address 1\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.address_1),expression:"branch.address_1"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Address 1 Character Limit 60","disabled":"","maxlength":_vm.max},domProps:{"value":(_vm.branch.address_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "address_1", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"address2","rules":"required|max:60","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Address 2\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.address_2),expression:"branch.address_2"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Address 2 Character Limit 60","disabled":"","maxlength":_vm.max},domProps:{"value":(_vm.branch.address_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "address_2", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"city","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    City\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.city),expression:"branch.city"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"City Character Limit 30","disabled":"","maxlength":_vm.max2},domProps:{"value":(_vm.branch.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "city", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"state","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    State\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.state),expression:"branch.state"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"State Character Limit 30","disabled":"","maxlength":_vm.max2},domProps:{"value":(_vm.branch.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "state", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Suburb\n                  ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.suburb),expression:"branch.suburb"}],attrs:{"type":"text","placeholder":"Suburb Character Limit 30","disabled":"","maxlength":_vm.max2},domProps:{"value":(_vm.branch.suburb)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "suburb", $event.target.value)}}})])]),_c('td',[_c('ValidationProvider',{attrs:{"name":"postal","rules":"required|max:10","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Postal Code\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.postal_code),expression:"branch.postal_code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Postal Code Character Limit 10","disabled":"","maxlength":_vm.max1},domProps:{"value":(_vm.branch.postal_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "postal_code", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"country","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Country\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.country),expression:"branch.country"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Country Character Limit 30","disabled":"","maxlength":_vm.max2},domProps:{"value":(_vm.branch.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "country", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|max:20","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Phone\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch.phone),expression:"branch.phone"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Phone Character Limit 20","disabled":"","maxlength":_vm.max4},domProps:{"value":(_vm.branch.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.branch, "phone", $event.target.value)}}})])]}}],null,true)})],1)])])]}}])})],1)]),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_cancel"},[_vm._v("Cancel")]),_c('a',{staticClass:"link_bt bt_save",on:{"click":_vm.submit}},[_vm._v("ADD")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }