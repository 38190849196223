<template>
  <div class="popup_sub_container">
    <div class="for_inside_addressbookmodal_adj">
      <AddContact
        @closeModal="closeAddAddress"
        v-if="showAddNew"
        :edit="is_edit"
        :editData="edit_data"
      />
    </div>
    <div class="address_book_header_wrapper">
      <div
        :class="category ? 'text_box_container text-box-container-w85':'text_box_container text-box-container-w60'"
      >
        <input type="text" v-model="search" placeholder="Search Here" />
      </div>
      <div class="address_filter_container" v-if="!category">
        <a class="address_filter_bt">Address Filter</a>
        <div class="address_filter_options">
          <ul>
            <li>
              <label>
                <input type="checkbox" v-model="filter.address_type.external" value="external" />
                <span>External</span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" v-model="filter.address_type.internal" value="internal" />
                <span>Internal</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <a class="add_new" v-if="auth().user_type == 'super_admin'" @click="showAddNew = true">Add New</a>
    </div>

    <div class="address_book_body_wrapper">
      <table>
        <tr>
          <td>NAME</td>
          <td>BRANCH NAME</td>
          <td>HUB</td>
          <td>ADDRESS</td>
          <td>EMAIL</td>
          <td><div v-if="auth().user_type == 'super_admin'"> ACTION</div> </td>
        </tr>
        <tbody v-if="!loading">
          <tr v-for="(address,i) in filteredAddress" :key="i">
            <td>{{address.name}}</td>
            <td>{{address.branch_name}}</td>
            <td><span v-if="address.branch && address.branch.division">{{address.branch.division.name}}</span></td>
            <td>{{address.address_1}},{{address.address_2}},{{address.postal_code}},{{address.city}},{{address.state}},{{address.country}}</td>
            <td>{{address.email}}</td>
            <td>
              <label v-if="!hideCheckbox">
                <input
                  type="checkbox"
                  v-model="address.is_checked"
                  @click="selectAddress(i,address.is_checked)"
                />
              </label>
              <a class="edit_icon" v-if="auth().user_type == 'super_admin'" @click="editAddress(address)"></a>
              <a class="delete_icon" v-if="auth().user_type == 'super_admin'" @click="deleteAddress(address.id)"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 v-if="!loading && filteredAddress.length == 0">No data found</h4>
      <loader size="50" v-if="loading" />
    </div>
    <section class="centered addressbook_modal_footer">
      <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
      <a class="link_bt bt_save" @click="selectedAddress(addresses)" v-if="!hideCheckbox">Select</a>
    </section>
  </div>
</template>
<script>
import AddContact from "./AddContact.vue";
export default {
  name: "AddressBookModal",
  components: {
    AddContact
  },
  data() {
    return {
      showAddNew: false,
      addresses: [],
      address_id: "",
      filter: {
        address_type: {
          external: true,
          internal: true
        }
      },
      search: "",
      is_edit: false,
      edit_data: {},
      loading: true,
      address_filter: {
        category: ""
      }
    };
  },
  created() {
    if (this.category) {
      this.filter.address_type.external = false;
      this.filter.address_type.internal = false;
      this.filter.address_type[this.category] = true;
    }
    this.fetchAddress();
  },
  computed: {
    filteredAddress() {
      return this.addresses.filter(address => {
        return address.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    closeAddAddress() {
      this.showAddNew = false;
      this.is_edit = false;
      this.edit_data = {};
      this.fetchAddress();
    },
    selectAddress(index, isChecked) {
      this.addresses = this.addresses.map(element => {
        element.is_checked = false;
        return element;
      });
      this.addresses[index].is_checked = isChecked;
    },
    editAddress(address) {
      this.is_edit = true;
      this.edit_data = address;
      this.showAddNew = true;
    },
    closeModal() {
      this.$emit("closeModal", {
        type: this.type
      });
    },
    async fetchAddress() {
      this.loading = true;
      this.addresses = [];
      try {
        if (
          this.filter.address_type.external ||
          this.filter.address_type.internal
        ) {
          let response = "";
          if (
            this.filter.address_type.external &&
            this.filter.address_type.internal
          ) {
            response = await this.axios.post("api/address/list");
          } else {
            if (this.filter.address_type.external) {
              this.address_filter.category = "external";
            } else if (this.filter.address_type.internal) {
              this.address_filter.category = "internal";
            }
            response = await this.axios.post(
              "api/address/list",
              this.address_filter
            );
          }
          this.addresses = response.data.msg.map(element => {
            element.is_checked = false;
            return element;
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    selectedAddress() {
      let address = this.addresses.find(element => element.is_checked == true);
      if (address) {
        this.$emit("selectedAddress", address);
        this.closeModal();
      } else {
        this.toast.error("Please select an address");
      }
    },
    deleteAddress(id) {
      if (confirm("Are you sure you want to delete this address?")) {
        this.axios
          .delete("/api/address/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchAddress();
          })
          .catch(error => {
            console.log("error", error.response);
          });
      }
    }
  },
  props: {
    type: String,
    category: String,
    hideCheckbox: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    "filter.address_type.external"() {
      this.fetchAddress();
    },
    "filter.address_type.internal"() {
      this.fetchAddress();
    }
  }
};
</script>
<style scoped>
.address_book_body_wrapper table {
  width: 100%;
}
.for_inside_addressbookmodal_adj {
  position: relative;
  height: 1px; /*udaipp*/
}
.for_inside_addressbookmodal_adj .add_contact_adj {
  position: absolute;
  z-index: 99;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #333;
}
.address_book_body_wrapper table tbody tr:first-child {
  font-weight: 400;
}
</style>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;
  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}
section.centered.addressbook_modal_footer {
  background: #f5f5f5;
  padding: 10px 0;
}
.address_book_header_wrapper {
  background: #f5f5f5;
  padding: 20px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  .text-box-container-w60 {
    width: 60%;
  }
  .text-box-container-w85 {
    width: 85%;
  }
  .text_box_container {
    height: 50px;
    input[type="text"] {
      width: 100%;
      height: 50px;
      background: #fff;
      border: 1px solid #ddd;
      padding: 0 20px;
    }
  }
  .address_filter_container {
    width: 25%;
    height: 50px;
    position: relative;
    background: #ffd10a;
    .address_filter_bt {
      width: 100%;
      height: 50px;
      background: #ffd10a;
      color: #000;
      text-align: center;
      line-height: 50px;
      display: block;
    }
    .address_filter_options {
      width: 100%;
      height: auto;
      min-height: 50px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50px;
      display: none;
      padding: 0 20px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      ul li {
        display: block;
        padding: 10px 0;
        label {
          cursor: pointer;
        }

        input {
          display: inline-block;
        }
        span {
          display: inline-block;
          margin-left: 20px;
          font-size: 14px;
          position: relative;
          top: -4px;
        }
      }
    }
    &:hover .address_filter_options {
      display: block;
    }
  }
  .add_new {
    background: #fff;
    height: 50px;
    line-height: 50px;
    width: 10%;
    text-align: center;
    display: block;
    border: 1px solid #ddd;
  }
}

.address_book_body_wrapper {
  padding-top: 50px;
  height: 360px;
  overflow: auto;
  border-bottom: 5px solid #ddd;
  table {
    border-collapse: collapse;
    font-size: 14px;
    tr {
      &:first-child {
        font-weight: bold;
        font-size: 16px;
      }
    }
    tbody {
      tr {
        &:first-child {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
    td {
      border-bottom: 1px solid #ddd;
      padding: 20px 5px;
      &:first-child {
        min-width: 200px;
      }
      &:last-child {
        min-width: 150px;
      }
      label {
        display: inline-block;
        margin-right: 5px;
      }
      .edit_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(../../assets/ico_edit.png) no-repeat;
        background-size: cover;
        margin-right: 5px;
      }
      .delete_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url(../../assets/ico_delete.png) no-repeat;
        background-size: cover;
        margin-right: 5px;
      }
    }
  }
}

a.link_bt {
  display: inline-block;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #333;
  color: #ccc;
  margin: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}
a.link_bt.bt_cancel {
  background: #000;
  color: #ffd10a;
}
a.link_bt.bt_save {
  background: #ffd10a;
  color: #000;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #ffd10a;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>