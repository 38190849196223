<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>P</th>
          <th>T</th>
          <th>A</th>
          <th>E</th>
          <th>O</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="p" value />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="t" value />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="a" value />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="e" value />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="o" value />
            </div>
          </td>

          <td class>
            <a class="table_action_btn" title="DELETE">DELETE</a>
            <a
              class="table_action_btn"
              title="CANCEL"
              v-show="makeEditable"
              @click="makeEditable=false,cancelEditable=true"
            >CANCEL</a>
            <a class="table_action_btn" title="UPDATE" v-show="makeEditable">UPDATE</a>
            <a
              class="table_action_btn"
              title="EDIT"
              v-show="cancelEditable"
              @click="makeEditable=true,cancelEditable=false"
            >EDIT</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "x",
  components: {},
  data() {
    return {
      makeEditable: false,
      cancelEditable: true
    };
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 13%;
}
.jobs_table.sp2 table tr th:last-child {
  width: 24%;
}
.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>