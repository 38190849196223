<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>BRANCH</th>
          <th class="width_33">USER TYPE</th>
          <th class="width_22">ACTIONS</th>
        </tr>
        <tr  v-for="(user,i) in users" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter" :value="user.name" :disabled="cancelEditable" />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter" :value="user.email" :disabled="cancelEditable" />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <select v-model="user.branch_id"  :disabled="cancelEditable">
                <option value="">Select Branch</option>
                <option v-for="(branch,i) in branches" :key="'branch'+i" :value="branch.id ">{{branch.name}}</option>
              </select>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <label class="chk_label">
                <span>User</span>
                <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 0"/>
              </label>
            </div>
            <div class="label_and_element_wrapper">
              <label class="chk_label">
                <span>Hub Admin</span>
                <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin == 0"/>
              </label>
            </div>
            <div class="label_and_element_wrapper">
              <label class="chk_label">
                <span>Customer Service</span>
                <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin === 1 && user.is_service_user == 1"/>
              </label>
            </div>
            <div class="label_and_element_wrapper">
              <label class="chk_label">
                <span>Super Admin</span>
                <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin === 1 && user.is_service_user == 0"/>
              </label>
            </div>
          </td>
          <td>
            <a class="edit_icon" title="EDIT" @click="user_id = user.id,is_editable=true"></a>
            <a class="delete_icon" title="DELETE" @click="deleteUser(user.id)"></a>
            <a class="view_icon" title="VIEW" @click="user_id = user.id, is_editable=false"></a>
          </td>
        </tr>
      </table>
    </div>

    <div class="black-overlay" v-if="user_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <UserManagementAddUserPopup v-if="user_id" :user_id="user_id" @closeModal="fetchUsers" :is_editable="is_editable" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserManagementAddUserPopup from "./UserManagement_AddUserPopup.vue";
export default {
  name: "UserManagement",
  components: {
    UserManagementAddUserPopup
  },
  data() {
    return {
      user_id:"",
      is_editable:true,
      cancelEditable: true,
      branches:[],
      users:[]
    };
  },
  created(){
    this.axios.get("/api/branch/view")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
    this.fetchUsers();
  },
  methods: {
    fetchUsers(){
      this.user_id = '';
      this.axios.get("/api/user/all")
      .then(response => {
          this.users = response.data.users;
      })
      .catch(error =>{
          console.log(error);
      });
    },
    deleteUser(id) {
      if (confirm('Are you sure you want to delete this User ?')) {
          this.axios.delete("/api/user/"+ id)
          .then(response => {
              this.toast.success(response.data.msg);
              this.fetchUsers();
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0;
}
.jobs_table.sp2 table tr td {
  padding: 5px 10px;
}
.chk_label {
  display: inline-block;
  margin-right: 20px;
  background: #fff;
  height: 40px;
  border: 1px solid #ccc;
}
.chk_label span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  top: 5px;
}
.chk_label input {
  display: inline-block;
  position: relative;
  top: 10px;
}
.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 0 5px;
}
.width_33 {
  width: 320px;
}
.width_22 {
  width: 160px;
}
.edit_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_edit.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.view_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_view.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
</style>