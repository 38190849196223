<template>
  <div class="TrackTracePop_wrapper">
    <header class="TTheader">
      <h2>TRACK AND TRACE</h2>
      <i class="closeme" @click="closeModal">X</i>
    </header>
    <div class="sub_head">Job Status - {{booking_prefix}}{{booking_id}}</div>
    <div class="TT_table_wrapper">
      <table>
        <tr>
          <td>STATUS</td>
          <td>DATE</td>
          <td>TIME</td>
          <td>CREATED BY</td>
          <td>LATITUDE</td>
          <td>LONGITUDE</td>
          <td>NAME</td>
          <td>SIGNATURE</td>
          <td>COMMENT</td>
        </tr>
        <tbody v-if="!loading">
          <tr v-for="(status,i) in courier" :key="i">
            <td>{{status.status}}</td>
            <td>{{status.date}}</td>
            <td>{{status.time}}</td>
            <td>{{status.created_by}}</td>
            <td>{{status.lat}}</td>
            <td>{{status.lon}}</td>
            <td>{{status.sender_or_receiver}}</td>
            <td>{{status.signature}}</td>
            <td> <span v-if="status.inbound_location && status.status=='Received' "> mailroom location = {{status.note}} </span> </td>
          </tr>
        </tbody>
      </table>
      <div style="height:100px;" v-if="loading">
        <loader />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrackTracePopup",
  data() {
    return {
      courier: [],
      loading:true
    };
  },
  created(){
    this.loading = true;
    this.axios.get("/api/booking/statuses/" + this.booking_id)
      .then(response => {
        console.log(response);
          this.courier = response.data.booking_statuses;
          this.loading = false;
      })
      .catch(error =>{
          this.loading = false;
      });
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  },
  props: ["booking_id","booking_prefix"]
};
</script>
<style lang="scss">
$theme-color: #ffd10a;
.TrackTracePop_wrapper {
  padding: 0;
  .TTheader {
    height: 80px;
    background: $theme-color;
    text-align: center;
    h2 {
      display: inline-block;
      padding: 20px 0 5px 0;
      border-bottom: 2px solid #000;
    }
    i.closeme {
      right: 20px;
      top: 20px;
    }
  }
  .sub_head {
    background: #fff;
    color: #000;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 0 20px;
  }
  .TT_table_wrapper {
    padding: 0 10px;
    table {
      width: 100%;
      border: 1px solid #ddd;
      border-right: 0;
      border-bottom: 0;
      border-collapse: collapse;
      background: #fff;
      tr {
        td {
          padding: 10px 5px;
          border: 1px solid #ddd;
          border-left: 0;
          border-top: 0;
        }
        &:first-child td {
          background: #eee;
          color: #000;
          font-weight: bold;
          border-bottom-color: #ccc;
          border-bottom-width: 2px;
        }
      }
    }
  }
}
</style>
<style scoped>
.TrackTracePop_wrapper .TT_table_wrapper table tbody tr:first-child td{font-weight: 400;}
</style>