<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPING</h4>
    <div class="step_view_wrapper">
      <div class="strip">
        <div class="strip_half" style="display:block"></div>
        <div class="strip_half"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three inactive">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>

    <section>
      <h2>SERVICE DETAILS</h2>
      <div class="form_section1">
        <table class="tablce_cell_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Item Reference
                  <span></span>
                </label>
                <input type="text" placeholder="Enter" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Package
                  <span></span>
                </label>
                <input type="text" placeholder="Enter" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Weight(Kg)
                  <span></span>
                </label>
                <input type="text" placeholder="Enter" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Items
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  L(m)
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  W(m)
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  H(m)
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  m3
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Cubic kg
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Charge Unit
                  <span></span>
                </label>
                <input type="text" placeholder="1" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  DG
                  <span></span>
                </label>
                <input type="checkbox" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Food
                  <span></span>
                </label>
                <input type="checkbox" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <h2>DESCRIPTION OF GOODS</h2>
      <div class="form_section1">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Enter the name of the goods
                  <span>*</span>
                </label>
                <textarea placeholder="Enter"></textarea>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Enter any remarks
                  <span>*</span>
                </label>
                <textarea placeholder="Enter"></textarea>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <section>
      <h2>INTERNATIONAL CHARGES</h2>
      <div class="form_section1">
        <table>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Declared Value
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Name" />
              </div>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Insurence Value
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Name" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <section>
      <h2>SCHEDULED PICKUP</h2>
      <div class="form_section1">
        <table>
          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Location of Packages
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Name" />
              </div>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  My Package Will Be Ready By
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <flat-pickr
                  v-model="time"
                  :config="configTime"
                  placeholder="Select Time"
                  name="birthtime"
                ></flat-pickr>
              </div>
            </td>
          </tr>

          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Pickup Date
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="date"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                ></flat-pickr>
              </div>
            </td>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Office Close At
                  <span>*</span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <flat-pickr
                  v-model="time"
                  :config="configTime"
                  placeholder="Select Time"
                  name="birthtime"
                ></flat-pickr>
              </div>
            </td>
          </tr>

          <tr>
            <td class="series_td">
              <div class="label_and_element_wrapper">
                <label>
                  Pickup Instructions
                  <br />(Upto 70 characters)
                  <span></span>
                </label>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <textarea placeholder="Description here"></textarea>
              </div>
            </td>
            <td class="series_td"></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>

    <section class="centered">
      <router-link to="/pages/ServiceDetails" class="link_bt bt_cancel">Previous</router-link>

      <a class="link_bt bt_cancel">Cancel</a>
      <router-link to="/pages/ServiceConfirm" class="link_bt bt_save">Save and Next</router-link>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "HelloWorld",
  components: {
    flatPickr
  },
  data() {
    return {
      date: null,
      time: null,
      enableTime: true,
      configTime: {
        enableTime: true,
        altInput: true,
        noCalendar: true
      },
      configDate: {
        altInput: true,
        noCalendar: false
      }
    };
  }
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #ffd10a;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #ffd10a;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.label_and_element_wrapper.sp {
  width: 33%;
}
.lone_chk_label {
  padding: 20px;
  display: block;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
td.series_td {
  width: 220px;
}
table.tablce_cell_adj tr td {
  vertical-align: top;
  padding: 0;
}
table.tablce_cell_adj tr td input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
}
table.tablce_cell_adj tr td:nth-child(-n + 2) {
  width: auto;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(-n + 10) {
  width: 100px;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(n + 11) {
  width: 60px;
}
</style>