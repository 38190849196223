<template>
  <div class="common_sub_container">
    <h2>USER MANAGEMENT</h2>
    <section>
      <div class="two_tabs">
        <a
          @click="showTab1=true,showTab2=false"
          v-bind:class="{ active: showTab1 }"
          class="tab_buttons"
        >ADD USER</a>
        <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >VIEW/ EDIT USERS</a>
      </div>
      <UserManagementAddUser v-if="showTab1"  @redirect="showTab2=true,showTab1=false"/>
      <UserManagementEditUser v-if="showTab2" />
    </section>
  </div>
</template>
<script>
import UserManagementAddUser from "./UserManagement_AddUser.vue";
import UserManagementEditUser from "./UserManagement_EditUser.vue";
export default {
  name: "x",
  components: {
    UserManagementAddUser,
    UserManagementEditUser
  },
  created(){
    this.$emit("breadCrumbData",["User Management"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  }
};
</script>
<style  scoped >
</style>