<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <table>
        <tr>
          <th>Subject</th>
          <th>Title</th>
          <th>Description</th>
          <th>Mailroom Location</th>
          <th>Action</th>
        </tr>
        <tr v-for="(data,i) in email_templates" :key="i">
          <td>
            <ValidationProvider name="subject" rules="required|max:30" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" v-model="data.subject" :disabled="!data.is_edit_mode"  :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
            </div>
             </ValidationProvider>
          </td>
          <td>
            <ValidationProvider name="title" rules="required|max:30" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" v-model="data.title" :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
            </div>
             </ValidationProvider>
          </td>
          <td>
            <ValidationProvider name="description" rules="required|max:30" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" v-model="data.description" :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
            </div>
             </ValidationProvider>
          </td>
          <td>
            <ValidationProvider name="loc" rules="required" v-slot="{ errors }" slim>
            <div class="label_and_element_wrapper">
                <select v-model="data.division_id" :disabled="!data.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select MailRoom Location</option>
                    <option :value="division.id" v-for="(division,i) in divisions" :key="'division_'+i">{{division.name}}</option>
                </select>
            </div>
             </ValidationProvider>
             
          </td>
          <td class="righted">
            <a class="table_action_btn" title="DELETE" @click="deleteEmailTemplate(data.id)">DELETE</a>
            <a class="table_action_btn" title="UPDATE" v-if="data.is_edit_mode" @click="updateEmailTemplate(data)">UPDATE</a>
            <a class="table_action_btn" title="CANCEL" v-if="data.is_edit_mode" @click="cancelEmailTemplate()">CANCEL</a>
            <a class="table_action_btn" title="EDIT" v-if="!data.is_edit_mode" @click="data.is_edit_mode=true">EDIT</a>
          </td>
        </tr>
      </table>
    </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "Department",
  components: {},
  data() {
    return {
      max:30,
      email_templates:[],
      divisions:[]
    };
  },
  created(){
    this.fetchEmailTemplate();
    this.fetchDivisions();
  },
  methods: {
    fetchEmailTemplate(){
      this.axios.get("/api/emailtemplate")
      .then(response => {
          this.email_templates = response.data.email_template.map(element => {
            element.is_edit_mode = false;
            return element;
          });
      })
      .catch(error =>{
          console.log(error);
      });
    },
    fetchDivisions(){
        this.axios.get("api/division")
                    .then(response => {
                        this.divisions = response.data.divisions;
                    })
                    .catch(error => {
                        console.log(error);
                    });
    },
    updateEmailTemplate(data){
      this.$refs.observer.validate();
      this.axios.put("api/emailtemplate/edit/"+ data.id,data)
                .then(response => {
                  this.toast.success(response.data.msg);
                  data.is_edit_mode = false;
                })
                .catch(error => {
                  console.log(error);
                });
    },
    deleteEmailTemplate(id){
      if(confirm("Are you sure you want to delete this email template?")){
        this.axios.delete("api/emailtemplate/delete/"+id)
        .then(response => {
          this.toast.success(response.data.msg);
          this.fetchEmailTemplate();
        })
        .catch(error => {
          console.log(error);
        }); 
      }
    },
    cancelEmailTemplate(){
      this.fetchEmailTemplate();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #ffd10a;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #ffd10a;
  color: #000;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>