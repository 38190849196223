var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp2"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('th',[_vm._v("Carrier Name")]),_c('th',[_vm._v("Carrier Code")]),_c('th',[_vm._v("Mode Of Dispatch")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Logo")]),_c('th',[_vm._v("Action")])]),_vm._l((_vm.carriers),function(carrier,i){return _c('tr',{key:i},[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(carrier.name),expression:"carrier.name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","maxlength":_vm.max2,"disabled":!carrier.is_edit_mode},domProps:{"value":(carrier.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(carrier, "name", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(carrier.carrier_code),expression:"carrier.carrier_code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","maxlength":_vm.max2,"disabled":!carrier.is_edit_mode},domProps:{"value":(carrier.carrier_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(carrier, "carrier_code", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"dispatch","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(carrier.mode),expression:"carrier.mode"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!carrier.is_edit_mode},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(carrier, "mode", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Mode of Dispatch")]),_vm._l((_vm.dispatches),function(mode,j){return _c('option',{key:'mode'+j,domProps:{"value":mode.id}},[_vm._v(_vm._s(mode.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"desc","rules":"required|max:70","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(carrier.description),expression:"carrier.description"}],class:errors[0] ? 'warning-border':'',attrs:{"placeholder":"Type Character Limit 70","maxlength":_vm.max,"disabled":!carrier.is_edit_mode},domProps:{"value":(carrier.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(carrier, "description", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('img',{attrs:{"src":carrier.logo_img}}),(carrier.is_edit_mode)?_c('label',{staticClass:"user_profile_upload"},[_c('span',[_vm._v("Choose a File")]),_c('input',{ref:'carrier_logo_'+i,refInFor:true,attrs:{"type":"file","accept":".jpg, .PNG","disabled":!carrier.is_edit_mode},on:{"change":function($event){return _vm.uploadLogo(i)}}})]):_vm._e()])]),_c('td',{},[_c('a',{staticClass:"table_action_btn",attrs:{"title":"DELETE"},on:{"click":function($event){return _vm.deleteCarrier(carrier.id)}}},[_vm._v("DELETE")]),(carrier.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"CANCEL"},on:{"click":function($event){return _vm.cancelCarrier(carrier)}}},[_vm._v("CANCEL")]):_vm._e(),(carrier.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"UPDATE"},on:{"click":function($event){return _vm.updateCarrier(carrier)}}},[_vm._v("UPDATE")]):_vm._e(),(!carrier.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"EDIT"},on:{"click":function($event){carrier.is_edit_mode=true}}},[_vm._v("EDIT")]):_vm._e()])])})],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }